import { useEffect }          from 'react';
import { useRouter }          from 'next/router';
import _ym, { YMInitializer } from 'react-yandex-metrika';
import { isProdAPI }
    from 'helpers/helpers';

const YA_CODE = 70759210;
const canUseYaMethods = () => {
  return typeof window !== 'undefined' && isProdAPI() && window[`yaCounter${YA_CODE}`];
};

export function ym (...args) {
  if (canUseYaMethods()) {
    _ym(...args);
  }
};

const YaMetrikaHOC = (WrappedComponent) => {
  function WithYaMetrika (props) {
    const router = useRouter();

    useEffect(() => {
      if (isProdAPI()) {
        ym('hit', router.asPath);
  
        router.events.on('routeChangeComplete', (url) => {
          ym('hit', url);
        });
      }
    }, []);

    return (
      <>
        <If condition={isProdAPI()}>
          <YMInitializer
            accounts={[YA_CODE]}
            options={{
              clickmap: true,
              trackLinks: true,
              accurateTrackBounce: true,
              webvisor: true,
              trackHash: true
            }}
            version="2"
          />
        </If>

        <WrappedComponent {...props}/>
      </>
    )
  }

  if (WrappedComponent.getInitialProps) {
    WithYaMetrika.getInitialProps = WrappedComponent.getInitialProps;
  }

  return WithYaMetrika;
}

export default YaMetrikaHOC;