const Common = {
  thisMonth: true,
  lastMonth: true,
  thisAllTime: true
};

const DashboardProfitRepairParts = {
  workshopId: true,
  tableProfitRepairParts: {
    stockGroupId: true,
    stockGroupName: true,
    totalPriceRepairPart: Common
  }
};

export default DashboardProfitRepairParts;