import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import {
  clearDataFromTypename,
  errorMessagesToStrings,
} from 'helpers/helpers';
import UINotifications from 'components/UI/Notifications';
import getConfig from 'next/config';
import isEmpty from 'lodash/isEmpty';
import { useProductEvents } from 'hooks';
import isArray from 'lodash/isArray';
import {
  SUBSCRIPTION_END_DATE_COOKIE_NAME,
  TOKEN_COOKIE_NAME,
} from 'helpers/constants';
import {
  eraseCookie,
  getCookie,
} from '../helpers/cookies';

const PREFIX = '@GRAPHQL_API';
const events = useProductEvents();
const now = new Date();
const alwaysAccessRequests = ['createBillPayment', 'sendPhoneCallVerificationCode', 'sendSmsVerificationCode', 'registerGarage', 'authenticateByCode'];
let subscriptionEndDate;

if (typeof document !== 'undefined') {
  subscriptionEndDate = getCookie(SUBSCRIPTION_END_DATE_COOKIE_NAME);
}

const defaultActionHandler = (state, action) => {
  return { ...state, ...action.payload };
};

function throwError(data) {
  const error = new Error('Error');
  error.data = data;
  throw error;
}

function getAPIUrl() {
  const { API_URL } = getConfig().publicRuntimeConfig;

  return typeof window === 'undefined' ? `${API_URL}/graphql` : '/api/graphql';
}

/* CONFIG */
/*
  resource: string
  fields: string
  mutations: array of strings
*/

export default function createCommonReducer(config) {
  const SET_LOADING = `${PREFIX}_${config.name}_SET_LOADING`;
  const SET_DATA = `${PREFIX}_${config.name}_SET_DATA`;
  const RESET_DATA = `${PREFIX}_${config.name}_RESET_DATA`;

  /* ACTIONS */

  /* --------------------------------------------------------------------------------- */
  function setLoading(loading = false) {
    return {
      type: SET_LOADING,
      payload: { loading },
    };
  }

  function setData(data = {}) {
    return {
      type: SET_DATA,
      payload: { loading: false, pristine: false, data },
    };
  }

  function resetData() {
    return {
      type: RESET_DATA,
      payload: { data: {} },
    };
  }

  function _generateQuery(args) {
    let _config = {};

    if (isArray(config.fields)) {
      _config = config.fields.reduce((prev, current) => {
        prev[current.resource] = current.fields;
        prev[current.resource].__args = args ? args[current.resource] ? args[current.resource] : args : {};

        return prev;
      }, {});

      return jsonToGraphQLQuery({ query: _config });
    }

    _config = { ...config.fields };

    if (args) {
      const innerArgs = args.where?.innerArgs;
      _config.__args = args;
      if (innerArgs) {
        delete _config.__args.where.innerArgs;
        const pathArray = innerArgs.entityPath;
        const pathArrayLength = pathArray.length;

        switch (pathArrayLength) {
          case 1:
            _config[pathArray[0]].__args = { ...innerArgs.params };
            break;
          case 2:
            _config[pathArray[0]][pathArray[1]].__args = { ...innerArgs.params };
            break;
          case 3:
            _config[pathArray[0]][pathArray[1]][pathArray[2]].__args = { ...innerArgs.params };
            break;
          case 4:
            _config[pathArray[0]][pathArray[1]][pathArray[2]][pathArray[3]].__args = { ...innerArgs.params };
            break;
        }
      }

    }

    const result = jsonToGraphQLQuery({
      query: { [config.resource || config.name]: _config },
    });

    return result;
  }

  function _generateMutation(name, args) {
    return jsonToGraphQLQuery({
      mutation: {
        [name]: {
          __args: args,
          ...config.fields,
        },
      },
    });
  }

  function reset() {
    return dispatch => {
      dispatch(resetData());
    };
  }

  function haveBusinessError(errorTypes) {
    let result = false;
    errorTypes.forEach((errorType) => {
      if (typeof errorType === 'string') {
        const subType = errorType.slice(-15);
        if (subType === 'BusinessProblem') {
          result = true;
        }
      }
    });
    return result;
  }

  function haveUnauthorizedError(errorTypes) {
    let result = false;
    errorTypes.forEach((errorType) => {
      if (errorType === 'UnauthorizedError') {
        result = true;
      }
    });
    return result;
  }

  function checkOnBlockedUser(errorTypes) {
    let result = false;
    errorTypes.forEach((errorType) => {
      if (errorType === 'Context creation failed: employee blocked') {
        result = true;
      }
    });
    return result;
  }

  function errorHandler(dispatch, err) {
    events.send('request_error_workshop', { error: { ...err.data } });

    dispatch(setLoading(false));

    if (!err?.data) {
      throw new Error('error handler empty data');
    }

    const errorTypes = err.data.map((item) => {
      return item.type || item.message;
    });

    let isBusinessError = false;
    let isUnauthorizedError = false;
    let isBlockedUser = false;

    if (errorTypes) {
      isBusinessError = haveBusinessError(errorTypes);
      isUnauthorizedError = haveUnauthorizedError(errorTypes);
      isBlockedUser = checkOnBlockedUser(errorTypes)
    }

    if (typeof window !== 'undefined') {
      if (isBusinessError) {
        return Promise.reject(err);
      } else if (isUnauthorizedError) {
        eraseCookie(TOKEN_COOKIE_NAME);
        window.location = '/access';
      } else if (isBlockedUser) {
        UINotifications.show({
          type: 'danger',
          html: 'Пользователь заблокирован. Обратитесь в техподдержку.',
        });
        // console.log(JSON.stringify(err.data));
        eraseCookie(TOKEN_COOKIE_NAME);
        //window.location = '/access';
      } else {
        UINotifications.show({
          type: 'danger',
          html: errorMessagesToStrings(err.data),
        });
        console.log(JSON.stringify(err.data));
      }
    } else {
      // console.log(JSON.stringify(err.data));
    }

    return Promise.reject(err);
  }

  function mutation(name, args) {
    if (!subscriptionEndDate || subscriptionEndDate && (+new Date(subscriptionEndDate) - +now > 0) || alwaysAccessRequests.includes(name)) {
      return dispatch => {
        dispatch(setLoading(true));

        return fetch('/api/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ query: _generateMutation(name, args) }),
        })
          .then(res => res.json())
          .then((res) => {
            if (!isEmpty(res.errors)) {
              throwError(res.errors);
            }

            const _res = clearDataFromTypename(res);
            const data = _res.data[name];

            dispatch(setData(data));

            return Promise.resolve(_res);
          }).catch(errorHandler.bind(this, dispatch));
      };
    } else {
      UINotifications.show({
        type: 'danger',
        text: 'У вас истекла подписка. Оплатите подписку, чтобы продолжить работать в системе.',
      });
      return () => {
        return Promise.reject({});
      };
    }
  }

  function query(args) {
    return dispatch => {
      dispatch(setLoading(true));

      return fetch(getAPIUrl(), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query: _generateQuery(args) }),
      })
        .then(res => res.json())
        .then((res) => {
          if (!isEmpty(res.errors)) {
            throwError(res.errors);
          }
          //const _res = clearDataFromTypename(res);
          //const data = isArray(config.fields) ? _res.data : _res.data[config.resource || config.name];

          const data = isArray(config.fields) ? res.data : res.data[config.resource || config.name];

          dispatch(setData(data));

          return Promise.resolve(res);
        })
        .catch((err) => {
          errorHandler(dispatch, err);
        });
    };
  }

  /* ACTION HANDLERS */
  /* --------------------------------------------------------------------------------- */
  const ACTION_HANDLERS = {
    [SET_LOADING]: defaultActionHandler,
    [SET_DATA]: defaultActionHandler,
    [RESET_DATA]: defaultActionHandler,
  };

  query.reset = reset;

  const mutations = {};

  if (!isEmpty(config.mutations)) {
    config.mutations.forEach((mutationName) => {
      mutations[mutationName] = mutation.bind(this, mutationName);
    });
  }

  return {
    reducer: (
      state = {
        loading: false,
        pristine: true,
        data: {},
      },
      action,
    ) => {
      let handler = ACTION_HANDLERS[action.type];

      return handler ? handler(state, action) : state;
    },
    query,
    mutations,
  };
}
