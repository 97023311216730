import Pagination   from './../common/pagination';
import { Money }    from '../common/money';

const BillCommodity = {
  id: true,
  name: true,
  status: true,
  type: true,
  duration: true,
  percent: true,
  price: Money,
  billAccountId: true,
  createdAt: true,
  updatedAt: true
}

const BillCommodities = {items: BillCommodity, ...Pagination}

export {
  BillCommodity,
  BillCommodities
}