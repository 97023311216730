import getConfig from 'next/config';

export const TOKEN_COOKIE_NAME = "zencar.workshop.token";
export const SUBSCRIPTION_END_DATE_COOKIE_NAME = "zencar.workshop.subscription_end_date";

export const MAPBOX_TOKEN = "pk.eyJ1Ijoic3lhcmRvdiIsImEiOiJjazhvcXAwbDAxMDRwM21vMnZ1dXF4dW1vIn0.Io9B1XeX74LAk5XmZfZCXQ";

export const LANGS = ['ru', 'en', 'de', 'ka'];

export const DEFAULT_LIST_LIMIT = 20;

export const PROJECT_NAME = "ZEN.CAR";

export const API_URL = getConfig().publicRuntimeConfig.API_URL;

export const EXTERNAL_API_URL = getConfig().publicRuntimeConfig.EXTERNAL_API_URL;

export const PDFRenderURL = getConfig().publicRuntimeConfig.PDF_URL;

export const AMPLITUDE_API_KEYS = {
  dev: "47a6422d841961bbcea6256c3fc8eb04",
  prod: "bdb930eeb6c14af04084bfa726f7e4c5"
}

export const RepairStatuses = ['preorder', 'inWork', 'completed', 'closed', 'canceled'];

export const RepairStatusesTransitions = {
  preorder: ['inWork', 'canceled'],
  inWork: ['completed', 'preorder', 'canceled'],
  completed: ['closed', 'inWork'],
  closed: [],
  canceled: []
};

export const ROADMAP_FEATURE_STATUSES = ["planned", "developing", "released"];

export const RUB = "₽";

export const VEHICLE_KINDS = ['CAR', 'COMMERCIAL', 'KART', 'MOTO', 'OTHER', 'SPECIAL', 'TRAILER', 'WATER'];
// варианты возможных юр и физ лиц здесь 
export const COUNTERPARTY_TYPES = {
  // legalEntity: "LEGAL_ENTITY",
  // soleTrader: "SOLE_TRADER",
  // individual: "INDIVIDUAL" 
  // WorkshopLegalEntity: "LEGAL_ENTITY",
  // WorkshopSoleTrader: "SOLE_TRADER",
  // WorkshopIndividual: "INDIVIDUAL" 
  legalEntity: "WorkshopLegal",
  soleTrader: "WorkshopSoleTrader",
  // individual: "WorkshopIndividual"
};

export const OWNER_LEGAL_ENTITY_TYPES = ['WorkshopLegal', 'WorkshopSoleTrader', 'WorkshopIndividual']

export const SUPPORT_PHONE = {
  display: "8 800 777-00-77",
  raw: "88007770077"
};

export const ROLES = {
  mechanic: "MECHANIC",//работник ремзоны
  master: "MASTER",//мастер-приемщик
  director: "DIRECTOR",//управляющий
  cashier: "CASHIER",//кассир
  zapchastist: "ZAPCHASTIST"//менеджер ОЗЧ
};

export const EMPLOYEE_ROLES = ['MECHANIC', 'MASTER', 'DIRECTOR', 'CASHIER', 'ZAPCHASTIST'];

export const BOOKING_STATUSES = ['NOT_PROCESSED', 'RECORDED', 'ARRIVED', 'CANCELED'];

export const BOOKING_STATUSES_FLOW = {
  'NOT_PROCESSED': ['RECORDED', 'CANCELED'],
  'RECORDED': ['ARRIVED', 'CANCELED'],
  'ARRIVED': [],
  'CANCELED': []
};

export const BOOKING_ROLE_FILTER_COOKIE_NAME = "zencar.booking.role.filter";

export const BOOKING_ROLES_FILTER_VALUES = ['ALL', 'MECHANIC', 'OTHER'];

export const VAT = [-1, 0, 10, 18, 20];

export const GARAGE_EXTRA_SERVICES = ['WIFI', 'COFFEE', 'KIDS_SPASE', 'CARD_PAYMENT', 'CUSTOMER_AREA', 'PARKING', 'CARWASH'];

export const ARTICLES_BY_CATEGORIES_LIMIT = 21;

export const GTM = 'GTM-N3KC5FM';

export const GA_ID = 'G-9KHSF2X2CQ';

export const NEW_REPAIR_LC_KEY = 'zencar.newRepair.data';

export const NEW_RECEIVE_LC_KEY = 'zencar.newReceive.data';

export const WORKBENCH_WIDGETS_KEY = 'zencar.workbench.widgets';

export const HIDE_REPAIR_WORK_PERFORMER_LC_KEY = 'zencar.hideRepairWorkPerformer';

export const HIDE_REPAIR_PART_PERFORMER_LC_KEY = 'zencar.hideRepairPartPerformer';

export const LANGUAGE_COOKIE_NAME = 'NEXT_LOCALE';

export const STOCKS_LIST_TYPE_COOKIE_NAME = 'zencar.stocksListType';

export const WORKBENCH_WIDGETS = ['vehicleForDelivery', 'profitForDay', 'profitForMonth', 'reviews', 'openRepairs', 'repairPartPercent', 'newClients', 'clients'];

export const OPTIMIZELY_DEV_KEY = '7Jo8BWVYia9oX3iEtQcon';

export const OPTIMIZELY_PROD_KEY = '5SyzvMVYCmeY1u6VbmukU';

export const ANONIMOUS_CLIENT_ID_COOKIE_NAME = 'zencar.client.anonimousId';