import Pagination from './../common/pagination';
import { File }   from './../common/files';

const ArticleCategory = {
  id: true,
  name: true,
  slug: true,
  privateArticle: true,
  createdAt: true,
  updatedAt: true
}

const ArticleListItem = {
  id: true,
  title: true,
  status: true,
  keywords: true,
  tags: true,
  viewsCount: true,
  publishedAt: true,
  slug: true,
  articleCategoryId: true,
  articleCategory: ArticleCategory,
  photos: File,
  createdAt: true,
  updatedAt: true
};

const Article = {
  ...ArticleListItem,
  content: true,
};

const Articles = { items: ArticleListItem, ...Pagination };

export {
  Articles,
  ArticleListItem,
  Article
}