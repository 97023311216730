import Pagination from './../../common/pagination';
import { till }   from '../tills';
import employee   from '../../common/employee';

const TillDocument = {
  id: true,
  tillId: true,
  idempKey: true,
  printable: true,
  status: true,
  providerRel: {
    id: true,
    providerId: true,
  },
  __typename: true,
  cashierName: true,
  cashierData: true,
  employee: employee,
  till: till,
  statusMessage: true,
  createdAt: true,
};

const TillDocuments = {items: TillDocument, ...Pagination};

export {
  TillDocument,
  TillDocuments,
};
