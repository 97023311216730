import { useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import { useStore } from 'lib/store';
import { Provider } from 'react-redux';
import NProgress from 'nprogress';
import UINotifications from 'components/UI/Notifications';
import {
  getUserFromRequestToken,
  isProd,
  isProdAPI,
  log,
} from 'helpers/helpers';
import {
  OPTIMIZELY_DEV_KEY,
  OPTIMIZELY_PROD_KEY,
} from 'helpers/constants';
import {
  AppContext,
  useLocalesHMR,
  useProductEvents,
  UserContext,
} from 'hooks';
import {
  getAnonimousClientId,
  setAnonimousClientId,
} from 'helpers/anonimousClientId';
import { UserAgentProvider } from '@quentin-sommer/react-useragent';
import YaMetrikaHOC from 'hocs/yaMetrika';
import {
  createInstance,
  OptimizelyProvider,
} from '@optimizely/react-sdk';
import SubscriptionInit from 'components/SubscriptionInit';

import '@fullcalendar/core/main.css';
import '@fullcalendar/timegrid/main.css';
import 'components/Forms/customValidations';
import 'styles/react-calendar/theme.scss';
import 'styles/semantic.scss';
import 'styles/all.scss';
import 'node_modules/mapbox-gl/dist/mapbox-gl.css';
import 'nprogress/nprogress.css';
import 'styles/swiper.scss';
import 'styles/new-year.css';

function MyApp({ Component, pageProps }) {
  const { userAgent, anonimousClientId, ...rest } = pageProps;
  const store = useStore(pageProps.initialReduxState);
  const router = useRouter();
  const events = useProductEvents();

  const optimizely = createInstance({
    sdkKey: isProdAPI() ? OPTIMIZELY_PROD_KEY : OPTIMIZELY_DEV_KEY,
    eventBatchSize: 10,
    eventFlushInterval: 10,
    datafileOptions: {
      updateInterval: 120000,
      autoUpdate: true,
    },
  });

  log('ANONIMOUS_CLIENT_ID', { value: anonimousClientId });

  useLocalesHMR();

  useEffect(() => {
    setAnonimousClientId(anonimousClientId);
    NProgress.configure({ showSpinner: false });

    router.events.on('routeChangeStart', () => NProgress.start());
    router.events.on('routeChangeComplete', (path) => {
      if (isProd()) {
        const { origin } = window.location;
        events.sendTo(
          ['amplitude'],
          'change_route_workshop',
          { path, origin },
        );
      }

      NProgress.done();
    });
    router.events.on('routeChangeError', () => NProgress.done());

    if (isProd()) {
      const { origin } = window.location;

      events.sendTo(
        ['amplitude'],
        'start_open_workshop',
        {
          path: router.asPath,
          pathname: router.pathname,
          origin,
        },
      );
    }
  }, []);

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="msapplication-TileColor" content="#5f6674" />
        <meta name="theme-color" content="#5f6674" />
        <meta name="yandex-verification" content="022c3bb6d9bc2f12" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#ff0034" />
        <If condition={isProdAPI()}>
          <script type="text/javascript" src="/vendors/roistat.js" />
        </If>
      </Head>

      <Provider store={store}>
        <SubscriptionInit />
        <UserAgentProvider ua={userAgent}>
          <AppContext.Provider value={{
            anonimousClientId,
          }}>
            <UserContext.Provider value={pageProps.user}>
              <OptimizelyProvider
                optimizely={optimizely}
                isServerSide={typeof window === 'undefined'}
                user={{ id: anonimousClientId }}
              >

                <Component {...rest} />
                <UINotifications />
              </OptimizelyProvider>
            </UserContext.Provider>
          </AppContext.Provider>

        </UserAgentProvider>
      </Provider>
    </>
  );
}

MyApp.getInitialProps = async ({ Component, router, ctx }) => {
  let pageProps = {
    userAgent: ctx.req ? ctx.req.headers['user-agent'] : navigator.userAgent,
    anonimousClientId: getAnonimousClientId(ctx.req),
  };

  if (Component.getInitialProps) {
    const initialProps = await Component.getInitialProps(ctx);
    Object.assign(pageProps, { ...initialProps });
  }

  pageProps.namespacesRequired = ['common'];
  pageProps.user = getUserFromRequestToken(ctx.req);

  return { pageProps };
};

export default YaMetrikaHOC(appWithTranslation(MyApp));