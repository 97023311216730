const Common = {
  thisDay: true,
  yesterday: true,
  avg10day: true,
  avg30day: true,
  thisWeek: true,
  lastWeek: true,
  avg10week: true,
  thisMonth: true,
  lastMonth: true,
  avgYear: true
};

const Percent = {
  thisDay: true,
  yesterday: true,
  in10day: true,
  in30day: true,
  thisAllTime: true
}

const DashboardProfit = {
  workshopId: true,
  repairStatus: true,
  profitTable: {
    totalPriceRepair: Common,
    worksTotalPriceRepair: Common,
    partsTotalPriceRepair: Common,
    percentWorksRepair: Percent,
    percentPartsRepair: Percent,
    netProfitRepair: Common,
    totalRepairCosts: Common,
  }
};

export default DashboardProfit;