import {
  AMPLITUDE_API_KEYS
}                          from 'helpers/constants';
import { isProdAPI }       from 'helpers/helpers';

const amplitudeKey = isProdAPI() ? AMPLITUDE_API_KEYS.prod : AMPLITUDE_API_KEYS.dev;
let amplitude;

export default function useAmplitude () {

  if (typeof window !== 'undefined') {
    amplitude = require('amplitude-js');
    amplitude.getInstance().init(amplitudeKey);
  }

  return amplitude;
};