import { 
  useEffect,
  useState,
  memo
}                  from 'react';
import PropTypes   from 'prop-types';
import cns         from 'classnames';
import isEmpty     from 'lodash/isEmpty';
import { Button }  from 'semantic-ui-react';
import styles      from './ui-notification-item.module.scss';

import DefaultIcon from '@material-ui/icons/NotificationsActiveOutlined';
import SuccessIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import InfoIcon    from '@material-ui/icons/InfoOutlined';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import ErrorIcon   from '@material-ui/icons/ErrorOutline';

const statusIconsMap = {
  default: <DefaultIcon/>,
  success: <SuccessIcon/>,
  info:    <InfoIcon/>,
  warning: <WarningIcon/>,
  danger:  <ErrorIcon/>
};

function UINotificationItem ({ 
  id, text, html, type, timeout, actions, destroy
}) {
  const [ animation, setAnimation ] = useState(false);
  let _timeout;

  useEffect(() => {
    setAnimation(true);

    if (timeout !== false) {
      _timeout = setTimeout(() => {
        destroy(id);
      }, timeout)
    }
  }, []);

  const _destroy = () => {
    clearTimeout(_timeout);
    destroy(id);
  };  

  return (
    <div
      className={cns(styles.component, {
        [styles.animation]: animation
      })}
      onClick={(event) => {
        event.stopPropagation();
        _destroy();
      }}
    >
      <div className={cns(styles.icon, styles[type])}>
        {statusIconsMap[type]}
      </div>
      <div className={styles.text}>
        <Choose>
          <When condition={html}>
            <div
              className={styles.text_content}
              dangerouslySetInnerHTML={{__html: html}}
            />
          </When>
          <Otherwise>
            <div className={styles.text_content}>
              {text}
            </div>
          </Otherwise>
        </Choose>

        <If condition={!isEmpty(actions)}>
          <div className={styles.actions}>
            {actions.map((
              { label, primary, onClick: _onClick = () => {} },
              index
            ) => (
              <Button
                key={index}
                size="mini"
                color={primary ? 'teal' : 'grey'}
                onClick={(event) => {
                  event.stopPropagation();

                  _onClick(event, { destroy: _destroy });
                }}
              >
                {label}
              </Button>
            ))}
          </div>
        </If>
      </div>
    </div>
  )
};

UINotificationItem.propTypes = {
  text: PropTypes.string,
  html: PropTypes.string,
  type: PropTypes.oneOf(['default', 'success', 'info', 'warning', 'danger']),
  layout: PropTypes.oneOf(['top-left', 'top-center', 'top-right', 'bottom-left', 'bottom-center', 'bottom-right']),
  timeout: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number
  ]),
  actions: PropTypes.array
};

UINotificationItem.defaultProps = {
  onClick: () => {}
};

export default memo(UINotificationItem, (prevProps, nextProps) => {
  return prevProps.id !== nextProps.id;
});