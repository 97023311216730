const ScheduleTemplateDay = {
  from: true,
  to: true,
  holiday: true
}

const GarageScheduleTemplate = {
  monday: ScheduleTemplateDay,
  tuesday: ScheduleTemplateDay,
  wednesday: ScheduleTemplateDay,
  thursday: ScheduleTemplateDay,
  friday: ScheduleTemplateDay,
  saturday: ScheduleTemplateDay,
  sunday: ScheduleTemplateDay,
};

export {
  GarageScheduleTemplate
}