const Common = {
  thisMonth: true,
  lastMonth: true,
  thisAllTime: true,
  avgAllGarages: true
};

const DashboardGaragePost = {
  workshopId: true,
  garagePostId: true,
  tableGaragePost: {
    countRepair: Common,
    sumCostPerHour: Common
  }
};

export default DashboardGaragePost;