const Common = {
  thisMonth: true,
  lastMonth: true,
  thisYear: true
};

const DashboardClients = {
  workshopId: true,
  tableClients: {
    countUniqClients: Common,
    avgCarEntries: Common,
    percentNewClients: Common,
    percentOldClients: Common
  }
};

export default DashboardClients;