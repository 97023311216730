import Pagination   from './../common/pagination';
import { Employee } from './../common/employee';
import { Client }   from './../common/client';
import { Vehicle }  from './../vehicle';
import {Money}      from "../common/money";
import {OfferWork}  from "../offer/offerWork";

const Booking = {
  id: true,
  startAt: true,
  status: true,
  workshopId: true,
  performerId: true,
  performer: Employee,
  vehicleId: true,
  vehicle: Vehicle,
  vehicleDescription: true,
  clientId: true,
  client: Client,
  repairId: true,
  note: true,
  repair: {
    id: true,
    status: true
  },
  offer: {
    id: true,
    workshopId: true,
    appealId: true,
    status: true,
    totalPrice: Money,
    contentText: true,
    works: [OfferWork],
  },
  description: true,
  /*author: {
    roles: true
  },*/
  createdAt: true,
  updatedAt: true
};

const Bookings = { items: Booking, ...Pagination };

export {
  Booking,
  Bookings
}