import Pagination            from './../common/pagination';

const VehicleWorkGroup = {
	id: true,
	name: true,
	parentId: true,
	providerRef: {
		uid: true,
		name: true
	}
}

const VehicleWorkGroups = { items: VehicleWorkGroup, ...Pagination };

export {
	VehicleWorkGroup,
	VehicleWorkGroups
}

export default VehicleWorkGroup;