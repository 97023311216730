import { addValidationRule } from 'formsy-react';
import { isValidNumber } from 'libphonenumber-js';
import { validate } from 'helpers/vin';
import isEmpty from 'lodash/isEmpty';

addValidationRule('isValidWorkHours', (values, value) => {
  return Number(value + 'e2') % 1 === 0;
});

addValidationRule('isValidPrice', (values, value) => {
  return Number(value + 'e2') % 1 === 0;
});

addValidationRule('isValidPSRN', (values, value) => {
  const _value = value || '';
  return _value.length === 0 || _value.length === 13 || _value.length === 15;
});

addValidationRule('isValidOKPO', (values, value) => {
  const _value = value || '';
  return _value.length === 0 || _value.length === 8 || _value.length === 10;
});

addValidationRule('isAboveZero', (values, value) => {
  return Number(value) > 0;
});

addValidationRule('equalOrAboveZero', (values, value) => {
  return Number(value) >= 0;
});

addValidationRule('maxIntValue', (values, fieldValue, validationValue) => {
  return Number(fieldValue) <= validationValue;
});

addValidationRule('minFloatValue', (values, fieldValue, validationValue) => {
  return Number(fieldValue) >= validationValue;
});

addValidationRule('notNegative', (values, fieldValue) => {
  return Number(fieldValue) >= 0;
});

addValidationRule(
  'maxDepotItemLimit',
  (values, fieldValue, validationValue) => {
    const currentVal = +fieldValue;

    return currentVal <= validationValue;
  }
);

addValidationRule('isValidPhone', (values, fieldValue) => {
  return isEmpty(fieldValue) || isValidNumber(fieldValue);
});

addValidationRule('isValidVIN', (values, fieldValue) => {
  return validate(fieldValue);
});

addValidationRule('isValidPrecision', (values, fieldValue, validationValue = 0) => {
  const _value = `${fieldValue}`;
  const split = _value.split('.');

  return split.length === 2 ? split[1].length <= validationValue : true;
});