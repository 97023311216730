import { Money } from '../common/money';

const supplierCartParts = {
  id: true,
  supplierOrderId: true,
  quantity: true,
  cost: Money,
  request_id: true,
  plugin_id: true,
  article: true,
  name: true,
  brand: true,
  status: true,
  statusState: true,
  depot: true,
  count: true,
  availableCount: true,
  pos_idx: true,
  deliveryAt: true,
  isActual: true,
  deliveryInDays: true,
  createdAt: true,
  supplier: {
    title: true,
    pluginName: true,
  }
};

export { supplierCartParts };