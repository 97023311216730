const SHOW_APP_NOTIFICATIONS = 'SHOW_APP_NOTIFICATIONS';
const TOGGLE_APP_MENU_SECTION_STATE = 'TOGGLE_APP_MENU_SECTION_STATE';
const SET_APP_MENU_SCROLL_POSITION = 'SET_APP_MENU_SCROLL_POSITION';
const TOGGLE_NOT_FEATURE_MODAL = 'TOGGLE_NOT_FEATURE_MODAL';
const TOGGLE_BLACK_FRIDAY_MODAL = 'TOGGLE_BLACK_FRIDAY_MODAL';
const SET_PAYMENT_MODAL_VISIBILITY = 'SET_PAYMENT_MODAL_VISIBILITY';
const LOAD_SECTIONS_MENU_STATE = 'LOAD_SECTIONS_MENU_STATE';
const LOAD_MENU_FAVOURITES = 'LOAD_MENU_FAVOURITES';

export function showAppNotifications(show = true) {
  return {
    type: SHOW_APP_NOTIFICATIONS,
    payload: show
  }
}

export function loadMenuFavourites(payload) {
  return {
    type: LOAD_MENU_FAVOURITES,
    payload
  }
}
export function loadMenuSections(payload) {
  return {
    type: LOAD_SECTIONS_MENU_STATE,
    payload
  }
}
export function toggleAppMenuSectionState(section) {
  return {
    type: TOGGLE_APP_MENU_SECTION_STATE,
    payload: section
  }
}

export function setAppMenuScrollPosition(position = 0) {
  return {
    type: SET_APP_MENU_SCROLL_POSITION,
    payload: position
  }
}

export function toggleNotFeatureModal(payload) {
  return {
    type: TOGGLE_NOT_FEATURE_MODAL,
    payload: payload
  }
}

export function toggleBlackFridayModal(payload) {
  return {
    type: TOGGLE_BLACK_FRIDAY_MODAL,
    payload: payload
  }
}

export function setPaymentModalVisibility(payload) {
  return {
    type: SET_PAYMENT_MODAL_VISIBILITY,
    payload: payload
  }
}

export const ACTION_HANDLERS = {
  [SHOW_APP_NOTIFICATIONS]: (state, action) => {
    return { ...state, notificationsOpened: action.payload }
  },
  [TOGGLE_APP_MENU_SECTION_STATE]: (state, action) => {
    const menu = { ...state.menu };

    menu[action.payload] = !state.menu[action.payload];
    localStorage.setItem('menuSections', JSON.stringify(menu))
    return { ...state, menu }
  },
  [LOAD_SECTIONS_MENU_STATE]: (state, action) => {
    const { payload: menu } = action
    return { ...state, menu }
  },
  [LOAD_MENU_FAVOURITES]: (state, action) => {
    const { payload: favourites } = action
    return { ...state, favourites }
  },
  [SET_APP_MENU_SCROLL_POSITION]: (state, action) => {
    return { ...state, menuScrollPosition: action.payload }
  },
  [TOGGLE_NOT_FEATURE_MODAL]: (state, action) => {
    return { ...state, visibleNotFeatureModal: action.payload }
  },
  [TOGGLE_BLACK_FRIDAY_MODAL]: (state, action) => {
    return { ...state, visibleBlackFridayModal: action.payload }
  },
  [SET_PAYMENT_MODAL_VISIBILITY]: (state, action) => {
    return { ...state, visiblePaymentModal: action.payload }
  },
};

const initialState = {
  notificationsOpened: false,
  menu: {
    planning: true,
    repairs: true,
    crm: false,
    depot: false,
    settings: false,
    help: false
  },
  menuScrollPosition: 0,
  visibleNotFeatureModal: false,
  visibleBlackFridayModal: false,
  visiblePaymentModal: false
};

const AppReducer = (state = initialState, action) => {
  let handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

export default AppReducer;