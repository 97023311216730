import { supplierCartParts } from './supplierCartParts';

const createSupplierOrder = {
  id: true,
  request_id: true,
  status: true,
  createdAt: true,
  updatedAt: true,
  orderedAt: true,
  deliveryId: true,
  supplierCartParts: supplierCartParts,
};

export { createSupplierOrder };