import Pagination            from './../common/pagination';
import { GaragePostReserve } from './reserve';
import { GaragePostType }    from './postType';

const GaragePost = {
	id: true,
	name: true,
	workshopId: true,
	position: true,
	garagePostTypeId: true,
	garagePostType: GaragePostType,
	garagePostReserves: GaragePostReserve,
	createdAt: true,
	updatedAt: true
};

const GaragePosts = { items: GaragePost, ...Pagination };

export {
	GaragePost,
	GaragePosts
}