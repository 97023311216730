import Pagination            from './../common/pagination';
import User                  from './../common/user';
import { DepotItemSnapshot } from './itemSnapshot';

const DepotItemChange = {
  id: true,
  previousSnapshot: DepotItemSnapshot,
  currentSnapshot: DepotItemSnapshot,
  reason: true,
  employee: User,
  createdAt: true,
  updatedAt: true,
};

const DepotItemChanges = {items: DepotItemChange, ...Pagination};

export {
  DepotItemChange,
  DepotItemChanges,
};