import Pagination    from './../../common/pagination';
import { Money }     from './../../common/money';
import { Discount }  from './../../common/discount';
import { DepotItem } from './../../depot/items';

const AddonPart = {
  id: true,
  addonId: true,
  addon: {
    status: true,
    repair: {
      status: true
    }
  },
  depotItemId: true,
  depotItem: DepotItem,
  name: true,
  article: true,
  unitPrice: Money,
  unitCount: true,
  accepted: true,
  discount: Discount,
  originalPrice: Money,
  totalPrice: Money
}

const AddonParts = { items: AddonPart, ...Pagination };

export {
  AddonPart,
  AddonParts
}