import { Client } from './../../common/client';
import { Money }  from './../../common/money';

const ClientBillAccount = {
  id: true,
  type: true,
  clientId: true,
  client: Client,
  workshopId: true,
  garage: { id: true, name: true, phone: true },
  balance: Money,
  createdAt: true,
  updatedAt: true
};

const BillAccount = {
  id: true,
  balance: Money,
  //workshopId: true,
  //garage: { id: true, name: true, phone: true },
  subscriptionEndDate: true,
  transactions: {
    value: Money,
    description: true
  },
  createdAt: true,
  updatedAt: true,
};

export {
  ClientBillAccount,
  BillAccount
}