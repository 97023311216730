import { File }   from '../common/files';
import Pagination from '../common/pagination';
import Client     from '../common/client';

const WorkshopReview_BASE = {
	id: true,
	status: true,
	clientId: true,
	client: Client,
	workshopId: true,
	repairId: true,
	price: true,
	quality: true,
	service: true,
	totalAvg: true,
	anonymously: true,
	commentEmployee: true,
	createdAt: true,
	updatedAt: true,
	comment: true
};

const WorkshopReview = {
	...WorkshopReview_BASE,
	comment: true,	
	photos: File
};

const WorkshopReviews = { items: WorkshopReview_BASE, ...Pagination };

export {
	WorkshopReview,
	WorkshopReviews
}

export default WorkshopReview;