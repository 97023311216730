const AppealWork = {
  id: true,
  appealId: true,
  name: true,
  action: true,
  modificationWorkId: true,
  wizardFlow: true
};

export {
  AppealWork
}