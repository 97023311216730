const setNewSupplierSettings = {
  cookie: true,
  can_save: true,
  error_code: true,
  status: true,
  time: true,
  message: true,
  params: true,
};

export {setNewSupplierSettings}