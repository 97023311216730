import {
  cloneDeep,
} from 'lodash';

const SET_PART_TO_CART_FROM_STORAGE = 'SET_PART_TO_CART_FROM_STORAGE';
const ADD_PART_TO_CART = 'ADD_PART_TO_CART';
const CHANGE_COUNT_PART = 'CHANGE_COUNT_PART';
const REMOVE_PART_IN_CART = 'REMOVE_PART_IN_CART';

export function setPartToCartFromStorage(payload) {
  return {
    type: SET_PART_TO_CART_FROM_STORAGE,
    payload: payload,
  };
}

export function addPartToCart(payload) {
  return {
    type: ADD_PART_TO_CART,
    payload: payload,
  };
}

export function changeCountPart(payload) {
  return {
    type: CHANGE_COUNT_PART,
    payload: payload,
  };
}

export function removePartInCart(payload) {
  return {
    type: REMOVE_PART_IN_CART,
    payload: payload,
  };
}

export const ACTION_HANDLERS = {
  [SET_PART_TO_CART_FROM_STORAGE]: (state, action) => {
    const partCartFromStorage = action.payload;
    return {cartParts: partCartFromStorage};
  },

  [ADD_PART_TO_CART]: (state, action) => {
    console.log(state.cartParts);
    const newCartParts = state.cartParts ? cloneDeep(state.cartParts) : [];
    newCartParts.items.push(action.payload);
    return {cartParts: newCartParts};
  },

  [CHANGE_COUNT_PART]: (state, action) => {
    const newCartParts = cloneDeep(state.cartParts);
    console.log(newCartParts);
    const updatedCart = newCartParts.items.map(part => {
      if (part.id === action.payload.id) {
        part.quantity = action.payload.quantity;
      }
      return part;
    });
    return {cartParts: {items: updatedCart}};
  },

  [REMOVE_PART_IN_CART]: (state, action) => {
    const newCartParts = cloneDeep(state.cartParts);
    const cartIndex = newCartParts.items.findIndex(part => part.id === action.payload.id);
    newCartParts.items.splice(cartIndex, 1);
    return {cartParts: newCartParts};
  },
};

const initialState = {
  cartParts: [],
};

const CartPartsReducer = (state = initialState, action) => {
  let handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

export default CartPartsReducer;