import Pagination from './../common/pagination';
import User from './../common/user';
import { Money } from './../common/money';
import { File } from './../common/files';
import { Employee } from './../common/employee';
import { Client } from './../common/client';
import {
  Vehicle,
  VehicleSimple,
} from './../vehicle';
import { GaragePostReserve } from './../garage/reserve';
import { Work } from './work';
import { Part } from './part';
import { ClientInvoice } from './../billing/client/invoice';
import { AppealForRepair } from './../appeal';
import { Counterparty } from '../counterparty';
import { Address } from '../common/address';

const DateFields = {
  createdAt: true,
  updatedAt: true,
  completedAt: true,
  closedAt: true,
};

const LegalEntityCommon = {
  id: true,
  type: {
    __aliasFor: "__typename"
  },
  name: true,
  counterpartyId: true,
  phone: true,
  email: true,
  inn: true,
  physicalAddress: Address,
};

const BankDetails = {
  id: true,
  name: true,
  currentAccount: true,
  correspondentAccount: true,
  rcbic: true,
  counterpartyId: true,
  mainBankDetail: true,
};

const RepairListItem = {
  id: true,
  status: true,
  description: true,
  note: true,
  vehicle: VehicleSimple,
  performer: User,
  client: Client,
  totalPrice: Money,
  clientInvoice: { paid: true },
  ...DateFields,
};

const checkCounterparty = () => {
  if (typeof repair.owner.name === 'string') {
    return Counterparty;
  } else {
    return Client;
  }
};
const Repair = {
  id: true,
  status: true,
  description: true,
  mileage: true,
  client: Client,
  owner: Client,
  vehicle: Vehicle,
  author: Employee,
  performer: Employee,
  garagePostReserves: GaragePostReserve,
  works: Work,
  parts: Part,
  totalPrice: Money,
  testimonial: true,
  workshopVehicleOwner: {
    __on: [
      {
        __typeName: 'WorkshopClient',
        fullName: {
          __aliasFor: "name",
          first: true,
          middle: true,
          last: true
        },
        phone: true,
        id: true,
        workshopId: true,
      },
      {
        __typeName: 'WorkshopIndividual',
        ...LegalEntityCommon,
        bankDetails: BankDetails,
        mainBankDetailId: true,
        registrationAddress: Address,
      },
      {
        __typeName: 'WorkshopSoleTrader',
        ...LegalEntityCommon,
        bankDetails: BankDetails,
        psrnsp: true,
        certificateNumber: true,
        legalAddress: Address,
      },
      {
        __typeName: 'WorkshopLegal',
        ...LegalEntityCommon,
        bankDetails: BankDetails,
        psrn: true,
        kpp: true,
        legalAddress: Address,
      },
    ],
  },
  attachments: File,
  closedDocuments: File,
  payment: {
    status: true,
    totalAmount: Money,
    paidAmount: Money,
    paymentItems: {
      __typename: true,
      id: true,
      name: true,
      status: true,
      unitCount: true,
      unitPrice: Money,
      totalAmount: Money,
      paidAmount: Money,
    },
    paymentOperations: {
      tillDocuments: {
        id: true,
        __typename: true,
        till: { name: true },
      },
      id: true,
      author: { id: true, phone: true },
      paymentAmount: {
        cash: {
          amount: true,
        },
        electronic: {
          amount: true,
        },
      },
      paymentOperationItems: {
        paymentMethod: true,
        name: true,
        unitPrice: {
          amount: true,
        },
        unitCount: true,
        totalPrice: {
          amount: true,
        },
      },
    },
  },
  note: true,
  clientInvoice: ClientInvoice,
  appeal: AppealForRepair,
  ...DateFields,
};

const Repairs = { items: RepairListItem, ...Pagination };

export {
  Repair,
  Repairs
};

export default Repair;