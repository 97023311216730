const APPLY_QUERY = 'FINDER_APPLY_QUERY';

const defaultHandler = (state, action) => ({
  ...state,
  ...action.payload
});

export function applyQuery (query = '') {
  return {
    type: APPLY_QUERY,
    payload: { query }
  }
};

export const ACTION_HANDLERS = {
  [APPLY_QUERY]: defaultHandler
};

const initialState = { query: '' };

const FinderReducer = (state = initialState, action) => {
  let handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

export default FinderReducer;