import Pagination from './../common/pagination';
import { Address } from './../common/address';
import { CommonCounterparty } from './common';
import { LegalEntityCommon } from './workshopCounterparty';
import { BankDetail, BankDetails } from './bankDetails';


const LegalEntity = {
  __on: [
    {
      __typeName: 'WorkshopClient',
      fullName: {
        __aliasFor: "name",
        first: true,
        middle: true,
        last: true
      },
      id: true,
      clientId: true,
      phone: true,
      workshopId: true,
    },

    {
      __typeName: 'WorkshopIndividual',
      ...LegalEntityCommon,
      bankDetails: BankDetail,
      mainBankDetailId: true,
      registrationAddress: Address,
    },
    {
      __typeName: 'WorkshopSoleTrader',
      ...LegalEntityCommon,
      bankDetails: BankDetail,
      psrnsp: true,

      // kpp: true,

      certificateNumber: true,
      legalAddress: Address,
    },
    {
      __typeName: 'WorkshopLegal',
      ...LegalEntityCommon,
      bankDetails: BankDetail,
      psrn: true,
      kpp: true,
      legalAddress: Address,
    },
  ]

};

const LegalEntities = { items: LegalEntity, ...Pagination };

export {
  LegalEntity,
  LegalEntities
}