import { Money }   from './../common/money';
import Pagination  from './../common/pagination';
import { Measure } from './../common/measure';

const Stock = {
  id: true,
  name: true,
  description: true,
  article: true,
  oem: true,
  brand: true,
  measure: Measure,
  workshopId: true,
  stockGroupId: true,
  stockGroup: {
    id: true,
    name: true,
    parent: {
      id: true,
      name: true
    }  
  },
  depotItems: {
    id: true,
    amount: {
      available: true
    },
    priceOut: Money
  },
  amount: {
    available: true,
    reserved: true,
    released: true
  },
  vat: true,
  createdAt: true,
  updatedAt: true
}

const Stocks = { items: Stock, ...Pagination };

export {
  Stock,
  Stocks
}