import { ClientBillAccount } from './account';
import { Money }             from './../../common/money';
import Pagination            from './../../common/pagination';

const ClientBillTransaction = {
  id: true,
  fromId: true,
  from: ClientBillAccount,
  toId: true,
  to: ClientBillAccount,
  amount: Money,
  type: true,
  clientPayment: {
    description: true
  },
  clientInvoice: {
    name: true,
    paidAt: true,
    status: true
  },
  createdAt: true,
  updatedAt: true,
};

const ClientBillTransactions = { items: ClientBillTransaction, ...Pagination };

export {
  ClientBillTransaction,
  ClientBillTransactions
};