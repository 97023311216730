import { Clients }         from './../entities/common/client';
import { Repairs }         from './../entities/repair';
import { VehiclesSimple }  from './../entities/vehicle';
import { WorkshopCounterparties }  from './../entities/counterparty';

const FIELDS = [
  { resource: 'vehicles', fields: VehiclesSimple },
  { resource: 'clients', fields: Clients },
  { resource: 'repairs', fields: Repairs },
  { resource: 'workshopCounterparties', fields: WorkshopCounterparties }
];

export default FIELDS;