import Pagination from './../common/pagination';
import { Money } from './../common/money';
import { PayrollScheme } from '../payroll';
import { Repair } from '../repair';

const PaymentForWork = {
  id: true,
  value: Money,
  payrollScheme: PayrollScheme,
  paid: true,
  dependentObject: {
    __typename: true,
    id: true,
    createdAt: true,
    __on: [
      {
        __typeName: 'RepairPart',
        name: true,
        description: true,
        repairId: true,
        totalPrice: Money,
        repair: {
          id: true,
          createdAt: true
        },
      },
      {
        __typeName: 'RepairWork',
        name: true,
        repairId: true,
        totalPrice: Money,
        repair: {
          id: true,
          createdAt: true
        },
      },
      {
        __typeName: 'Repair',
        id: true,
        totalPrice: Money,
      },
    ],
  },
  createdAt: true,
};

const PaymentForWorks = { items: PaymentForWork, ...Pagination };

const PaymentForWorkGroups = {
  items: PaymentForWork,
  timestamp: true,
  totalSum: Money,
};

export {
  PaymentForWork,
  PaymentForWorks,
  PaymentForWorkGroups,
};