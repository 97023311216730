import Pagination   from './../../common/pagination';
import { Money }    from './../../common/money';
import { Discount } from './../../common/discount';

const AddonWork = {
  id: true,
  addonId: true,
  addon: {
    status: true,
    repair: {
      status: true
    }
  },
  modificationWorkId: true,
  name: true,
  action: true,
  timeHrs: true,
  unitPrice: Money,
  unitCount: true,
  accepted: true,
  discount: Discount,
  originalPrice: Money,
  totalPrice: Money
}

const AddonWorks = { items: AddonWork, ...Pagination };

export {
  AddonWork,
  AddonWorks
}