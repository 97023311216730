import Pagination    from './../../common/pagination';
import { Money }     from './../../common/money';
import { AddonWork } from './work';
import { AddonPart } from './part';

const RepairAddon = {
  id: true,
  repairId: true,
  repair: {
    status: true,
    vehicle: {
      modificationId: true
    }
  },
  status: true,
  description: true,
  works: AddonWork,
  parts: AddonPart,
  partsTotalPrice: Money,
  worksTotalPrice: Money,
  originalPrice: Money,
  totalPrice: Money,
  createdAt: true,
  updatedAt: true
}

const RepairAddons = { items: RepairAddon, ...Pagination };

export {
  RepairAddon,
  RepairAddons
}