import Appeal      from '../appeal';

export const Subscription = {
  subscription: {
    events: {
      __typename: true,
      id: true,
      __on: [
        {
          __typeName: 'AppealCreatedEvent',
          entity: Appeal
        },
        {
          __typeName: 'GarageBookingCreatedEvent',
          entity: {
            id: true,
            startAt: true,
            author: {
              __typename: true,
            }
          }
        }
      ]
    }
  }
}