import Pagination      from './../common/pagination';
import { VehicleWork } from '../vehicleWork';
import { Money }       from '../common/money';

const VehicleWorkTime = {
	id: true,
	timeHrs: true,
	workId: true,
	catalogId: true,
	hourlyRate: Money,
	work: VehicleWork
};

const VehicleWorkTimes = { items: VehicleWorkTime, ...Pagination };

export {
	VehicleWorkTime,
	VehicleWorkTimes
}

export default VehicleWorkTime;