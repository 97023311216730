import { createContext, useContext } from 'react';
import isEmpty                       from 'lodash/isEmpty';

export const UserContext = createContext();

export default function useUser () {
  const user = useContext(UserContext);

  if (user) {
    user.oneOfRole = (roles = []) => {
      return !isEmpty(user.roles.filter(role => roles.includes(role)));
    }
  }

  return user;
};