const User = {
	id: true,
	name: { first: true, last: true, middle: true },
	phone: true,
	email: true,
	createdAt: true,
	updatedAt: true,
	settings: {
		lang: true,
		timezone: {
			name: true,
			offset: true
		}
	}
};

export default User;