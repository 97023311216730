import { supplierCartParts } from './supplierCartParts';
import { onlinePayment }          from './onlinePayment';
import { Money }             from '../common/money';

const supplierOrder = {
  id: true,
  status: true,
  createdAt: true,
  orderedAt: true,
  deliveryId: true,
  commentsEmployee: true,
  address: true,
  supplierCartParts: supplierCartParts,
  onlinePayments: onlinePayment
};

export { supplierOrder };