const workshopGoal = {
  id: true,
  workshopId: true,
  type: true,
  targetValue: true,
  startDate: true,
  endDate: true,
  children: {
    id: true,
    startDate: true,
    endDate: true,
    type: true,
    targetValue: true
  }
};

export { workshopGoal };