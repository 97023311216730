import { useState, useRef } from 'react';
import UINotificationItem   from './Item';
import cloneDeep            from 'lodash/cloneDeep';
import cns                  from 'classnames';
import { Portal }           from 'semantic-ui-react';
import styles               from './ui-notifications.module.scss';

const defaultConfig = {
  layout: 'bottom-right',
  timeout: 11000,
  type: 'default'
};

function UINotifications () {
  const [ pull, setPull ] = useState({});
  const pullRef = useRef(pull);

  pullRef.current = pull; //need for correct item destroy in setTimeout

  UINotifications.show = function (config) {
    const _pull = cloneDeep(pullRef.current);

    _pull[`${Date.now()}`] = {...defaultConfig, ...config};

    setPull(_pull);
  };

  const destroy = (id) => {
    const _pull = cloneDeep(pullRef.current);

    delete _pull[id]

    setPull(_pull);
  };

  const getGroupedPullByLayout = () => {
    let result = {};

    for (let key in pullRef.current) {
      const layout = pullRef.current[key].layout;
      const value = pullRef.current[key];
      
      if (!result[layout]) {
        result[layout] = {};
      }

      result[layout][key] = value;
    }

    return result;
  };

  const groupedPull = getGroupedPullByLayout();

  return (
    <Portal open={true}>
      <>
        <For each="layout" of={Object.keys(groupedPull)}>
          <div
            key={layout}
            className={cns(styles.component, styles[layout])}
          >
            <For each="id" of={Object.keys(groupedPull[layout])}>
              <UINotificationItem
                key={id}
                {...pullRef.current[id]}
                id={id}
                destroy={destroy}
              />
            </For>
          </div>
        </For>
      </>
    </Portal>
  )
};

export default UINotifications;