import Pagination from '../common/pagination';

const Suppliers = {
  id: true,
  pluginId: true,
  createdAt: true,
  updatedAt: true,
  properties: true,
  deletedAt: true,
  loaded: true,
  title: true,
  pluginName: true,
  icon: true,
}

const suppliers = { items: Suppliers, ...Pagination };

export {
  suppliers
}