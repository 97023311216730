import { Money }        from '../common/money';
import { Employee }     from '../common/employee';
import Pagination       from '../common/pagination';
import { TillDocument } from '../workshop/TillDocument';

const RepairPaymentOperation = {
  id: true,
  // paymentAmount: Money,
  paymentOperationItems: {
    name: true,
    unitPrice: Money,
    unitCount: true,
    totalPrice: Money,
    paymentMethod: true,
  },
  authorId: true,
  author: Employee,
  tillDocuments: TillDocument,
};

const RepairPaymentOperations = {items: RepairPaymentOperation, ...Pagination};

export {
  RepairPaymentOperation,
  RepairPaymentOperations,
};