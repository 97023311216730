import { Money }             from '../common/money';
import { Discount }          from '../common/discount';

const OfferWork = {
  id: true,
  offerId: true,
  name: true,
  action: true,
  modificationWorkId: true,
  timeHrs: true,
  unitPrice: Money,
  hourlyRate: Money,
  unitCount: true,
  unitDiscount: Discount,
  originalPrice: Money,
  totalPrice: Money
}

export {
  OfferWork
}