const RoleSettings = {
  sms: true,
  slack: true,
  email: true,
  push: true
}

const NotificationSettings = {
  client: RoleSettings,
  employee: RoleSettings
}

export {
  NotificationSettings
}