import Pagination            from './../common/pagination';
import { Offer }             from './../offer';
import { AppealWork }        from './work';
import { Vehicle }           from './../vehicle';
import { Client }            from './../common/client';
import { Garage }            from './../garage';

const AppealListItem = {
	id: true,
	status: true,
	timeWishes: true,
	otherWishes: true,
	convientTime: true,
	city: true,
	partsNecessity: true,
	partnerSource: true,
	works: AppealWork,
	offers: Offer,
	vehicle: Vehicle,
	author: Client,
	garage: Garage,
	createdAt: true,
	updatedAt: true
};

const Appeal = {
	id: true,
	status: true,
	timeWishes: true,
	otherWishes: true,
	convientTime: true,
	city: true,
	partsNecessity: true,
  partnerSource: true,
	works: AppealWork,
	offers: Offer,
	vehicle: Vehicle,
	author: Client,
	garage: Garage,
	createdAt: true,
	updatedAt: true
};

const AppealForRepair = {
	id: true,
	works: AppealWork
};

const Appeals = { items: AppealListItem, ...Pagination };

export {
	Appeal,
	Appeals,
	AppealForRepair
}

export default Appeal;