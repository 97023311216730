import { VehicleModificationWorkGroup } from './group';

const VehicleModificationWorkCatalog = {
  id: true,
  name: true,
  workshopId: true,
  groups: VehicleModificationWorkGroup
};

export {
	VehicleModificationWorkCatalog
}