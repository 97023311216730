const SupplierPart = {
  time_orig: true,
  brand: true,
  mcount: true,
  plugin_id: true,
  article: true,
  cat: true,
  cost: true,
  city: true,
  real_cat: true,
  stock: true,
  time_int: true,
  brand_id: true,
  pos_idx: true,
  multiplicity: true,
  count: true,
  name: true,
  chance: true,
}

const supplierParts = {
  minValueOrigPart: SupplierPart,
  minValueOrigReplacementPart: SupplierPart,
  minValueReplacementPart: SupplierPart,
  minTimePart: SupplierPart,
  parts: SupplierPart,
  request_id: true
};

export {supplierParts, SupplierPart};