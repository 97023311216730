import Pagination          from './../common/pagination';
import { Money }           from '../common/money';
import { Measure }         from '../common/measure';
import { Stock }           from './../stock';
import { Depot }           from './index';
import { DepotItemChange } from './itemChanges';
import { EnumType }        from 'json-to-graphql-query';

const DepotItem = {
  id: true,
  stockId: true,
  stock: Stock,
  depotId: true,
  depot: Depot,
  priceIn: Money,
  priceOut: Money,
  measure: Measure,
  amount: {
    available: true,
    reserved: true,
    released: true,
  },
  vat: true,
  createdAt: true,
  updatedAt: true,
};

const UsageFields = {
  amount: {
    available: true,
    reserved: true,
    released: true,
  },
  dependentObject: {
    id: true,
    createdAt: true,
    __on: {
      __typeName: "RepairPart",
      repairId: true
    }
  },
  transactions: {
    id: true,
    amount: {
      available: true,
      reserved: true,
      released: true,
    },
    createdAt: true
  }
};

const DepotItemWithChanges = {
  ...DepotItem,
  depotItemChanges: {
    __args: {
      order: { createdAt: new EnumType('desc') }
    },
    ...DepotItemChange
  },
  reserves: UsageFields,
  releases: UsageFields
};

const DepotItems = {items: DepotItem, ...Pagination};
const DepotItemsWithChanges = {items: DepotItemWithChanges, ...Pagination};

export {
  DepotItem,
  DepotItems,
  DepotItemsWithChanges
};