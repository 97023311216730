import Pagination from './pagination';

const Software = {
	id: true,
	phone: true,
	garage: { 
		id: true,
		name: true,
		address: {
			postcode: true,
			state: true,
			city: true,
			street: true,
			building: true
		}
	},
	createdAt: true,
	updatedAt: true 
};

const Softwares = { items: Software, ...Pagination };

export {
	Software,
	Softwares
}