import { Money }  from './../../common/money';
import Pagination from './../../common/pagination';

const ClientInvoice = {
  id: true,
  name: true,
  status: true,
  paymentUrl: true,
  paid: true,
  paidAt: true,
  canceled: true,
  canceledAt: true,
  paymentAmount: Money,
  amountPayable: Money,
  discountPrice: Money,
  repairId: true,
  createdAt: true,
  updatedAt: true
};

const ClientInvoices = { items: ClientInvoice, ...Pagination };

export {
  ClientInvoice,
  ClientInvoices
};