const _StockGroup = {
  id: true,
  name: true,
  children: {
    id: true,
    name: true,
    parentId: true,
    createdAt: true,
    updatedAt: true
  },
  parentId: true,
  parent: {
    id: true,
    name: true,
    parentId: true,
    createdAt: true,
    updatedAt: true
  },
  stocks: {
    amount: {
      available: true
    }
  },
  createdAt: true,
  updatedAt: true
}

const StockGroup = {
  id: true,
  name: true,
  children: _StockGroup,
  parentId: true,
  parent: _StockGroup,
  stocks: {
    amount: {
      available: true
    }
  },
  createdAt: true,
  updatedAt: true
}

export {
  StockGroup
}