const Common = {
  thisMonth: true,
  lastMonth: true,
  thisAllTime: true,
  avgAllGarages: true
};

const DashboardEmployee = {
  workshopId: true,
  employeeId: true,
  tableEmployee: {
    madeRepair: Common,
    avgTotalPriceRepair: Common,
    totalPriceRepairPart: Common,
    totalPriceRepairWork: Common
  }
};

export default DashboardEmployee;