import Pagination from './../common/pagination';

const BankDetail = {
  id: true,
  name: true,
  currentAccount: true,
  correspondentAccount: true,
  rcbic: true,
  counterpartyId: true,
  mainBankDetail: true,
  createdAt: true,
  updatedAt: true
}

const BankDetails = { items: BankDetail, ...Pagination };

export {
  BankDetail,
  BankDetails
}