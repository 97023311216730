import { useEffect } from 'react';
import { jsonToGraphQLQuery } from 'json-to-graphql-query';
import { createClient } from 'graphql-ws';
import {
  getAuthHeader,
  isProdAPI,
} from 'helpers/helpers';
import { Subscription } from 'api/entities/subscription';
import UINotifications from 'components/UI/Notifications';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import API from 'api';
import { setCookie } from '../helpers/cookies';
import { SUBSCRIPTION_END_DATE_COOKIE_NAME } from '../helpers/constants';

export default function useSubscription() {
  const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    const client = createClient({
      url: isProdAPI() ? 'wss://graphql.zencar.tech/graphql' : 'wss://zencar-backend-dev.dev.zencar.tech/graphql',
      reconnect: true,
      connectionParams: {
        ...getAuthHeader(),
      },
    });

    async function execute(payload) {
      return new Promise((resolve, reject) => {
        let result;
        client.subscribe(payload, {
          next: (data) => {
            result = data;
            const eventType = data?.data?.events?.__typename;

            switch (eventType) {
              case 'AppealCreatedEvent':
                onNewAppealEvent(data.data.events?.entity);
                break;
              case 'BillAccountUpdatedEvent':
                onUpdateBillAccount();
                break;
              case 'GarageBookingCreatedEvent':
                onCreateGarageBooking(data.data.events?.entity);
                break;
            }
          },
          error: reject,
          complete: () => resolve(result),
        });
      });
    }

    (async () => {
      try {
        const result = await execute({
          query: jsonToGraphQLQuery(Subscription),
        });

        console.log('result:', result);
      } catch (err) {
        console.log(err);
      }
    })();

  }, []);

  const onNewAppealEvent = (appeal) => {
    const { vehicle } = appeal;
    const getVehicleString = () => {
      let result = '';
      if (vehicle?.modification?.model?.name && vehicle?.modification?.model?.manufacturer?.name) {
        result += `${vehicle.modification.model.manufacturer.name} ${vehicle.modification.model.name}`;

        if (vehicle?.year) {
          result += ` ${vehicle?.year} г.`;
        }
      }

      return result;
    };
    UINotifications.show({
      type: 'info',
      html: `Поступила новая заявка на ремонт ${getVehicleString()}`,
      timeout: false,
      actions: [{
        label: 'Подробнее',
        primary: true,
        onClick: (e, actions) => {
          router.push({ pathname: `/appeals`, query: { tab: 'REQUEST_OFFERS' } });
          actions.destroy();
        },
      }],
    });
  };

  const onUpdateBillAccount = () => {
    UINotifications.show({
      type: 'info',
      html: `Дата окончания подписки изменена.`,
      timeout: 5000,
    });
    dispatch(API.queries.billAccount())
      .then((resp) => {
        if (resp.data?.billAccount?.subscriptionEndDate) {
          setCookie(SUBSCRIPTION_END_DATE_COOKIE_NAME, resp.data?.billAccount?.subscriptionEndDate);
          if (typeof window !== 'undefined') {
            window.location = '/workbench';
          }
        }
      })
  };

  const onCreateGarageBooking = (booking) => {
    if (booking?.author?.__typename === 'Client') {
      const date = booking.startAt.substring(0, 10);

      UINotifications.show({
        type: 'info',
        html: `Новая запись на ремонт.`,
        timeout: false,
        actions: [{
          label: 'Подробнее',
          primary: true,
          onClick: (e, actions) => {
            router.push({ pathname: `/planning/arrivals/date/${date}`, query: { role: 'ALL' } });
            actions.destroy();
          },
        }],
      });
    }
  };
}