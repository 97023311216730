import {
  Client,
  Clients,
} from './entities/common/client';
import {
  Employee,
  Employees,
} from './entities/common/employee';
import { Profile } from './entities/common/profile';
import {
  Software,
  Softwares,
} from './entities/common/software';
import AccessToken from './entities/common/token';
import VerificationCode from './entities/common/verificationCode';
import { File } from './entities/common/files';
import { Measure } from './entities/common/measure';
import {
  Garage,
  GaragePost,
  GaragePostReserve,
  GaragePostReserves,
  GaragePosts,
  GaragePostType,
  GaragePostTypes,
  Garages,
} from './entities/garage';

import {
  Repair,
  Repairs,
} from './entities/repair';
import { Work } from './entities/repair/work';
import { Part } from './entities/repair/part';
import {
  RepairAddon,
  RepairAddons,
} from './entities/repair/addon';
import {
  AddonWork,
  AddonWorks,
} from './entities/repair/addon/work';
import {
  AddonPart,
  AddonParts,
} from './entities/repair/addon/part';

import {
  GlobalVehicle,
  Vehicle,
  Vehicles,
} from './entities/vehicle';
import { VehicleManufacturer } from './entities/vehicle/manufacturer';
import { VehicleModel } from './entities/vehicle/model';
import { VehicleModification } from './entities/vehicle/modification';
import { VehicleModificationWorkCatalog } from './entities/vehicle/modification/work/catalog';
import { Recommendation } from './entities/vehicle/recommendation';
import {
  Depot,
  Depots,
} from './entities/depot';
import {
  DepotItem,
  DepotItemsWithChanges,
} from './entities/depot/items';
import {
  ReceiveDepotOperation,
  ReceiveDepotOperations,
} from './entities/depot/receiveOperations';
import { DepotItemChanges } from './entities/depot/itemChanges';
import {
  Stock,
  Stocks,
} from './entities/stock';
import { StockGroup } from './entities/stock/group';
import {
  BankDetail,
  BankDetails,
  Counterparties,
  Counterparty,
  WorkshopCounterparties,
  WorkshopCounterparty,
  Individual,
  Individuals,
  LegalEntities,
  LegalEntity,
  SoleTrader,
  SoleTraders,
} from './entities/counterparty';
import {
  Document,
  DocumentType,
} from './entities/document';
import {
  Booking,
  Bookings,
} from './entities/booking';
import { Appeals } from './entities/appeal';
import {
  Offer,
  OfferWork,
} from './entities/offer';
import { VehicleWorkGroups } from './entities/vehicleWorkGroup';
import { VehicleWork } from './entities/vehicleWork';
import {
  VehicleWorkTime,
  VehicleWorkTimes,
} from './entities/vehicleWorkTime';
import { ArticleCategories } from './entities/article/category';
import {
  Article,
  Articles,
} from './entities/article';
import DashboardProfit from './entities/dashboard/profit';
import DashboardRepair from './entities/dashboard/repair';
import DashboardClients from './entities/dashboard/clients';
import DashboardAppealAndOffer from './entities/dashboard/appealAndOffer';
import DashboardEmployee from './entities/dashboard/employee';
import DashboardPaymentForWork from './entities/dashboard/payment';
import DashboardGaragePost from './entities/dashboard/garagePost';
import DashboardProfitRepairParts from './entities/dashboard/profitRepairParts';
import BenchmarkWorkshop from './entities/dashboard/benchmark';
import GlobalSearch from './customRequests/globalSearch';
import {
  WorkshopReview,
  WorkshopReviews,
} from './entities/workshop/review';
import { ClientBillAccount, BillAccount } from './entities/billing/client/account';
import { ClientBillTransactions } from './entities/billing/client/transaction';
import {
  ClientInvoice,
  ClientInvoices,
} from './entities/billing/client/invoice';
import {
  PayrollScheme,
  PayrollSchemes,
  PayrollSchemeTemplate,
} from './entities/payroll';
import {
  PaymentForWorkGroups,
  PaymentForWorks,
} from './entities/paymentForWork';
import { Performer } from './entities/common/performer';
import {
  WorkshopIntegration,
  WorkshopIntegrations,
} from './entities/workshop/integrations';
import {
  till,
  tills,
} from './entities/workshop/tills';
import {
  TillDocument,
  TillDocuments,
} from './entities/workshop/TillDocument';
import {
  RepairPaymentOperation,
  RepairPaymentOperations,
} from './entities/repairPaymentOperation';
import {
  repairPayment,
  repairPayments,
} from './entities/repairPayment';
import { getDetails } from './entities/parts/getDetails';
import { supplierParts } from './entities/parts/supplierParts';
import { createSupplierCartPart } from './entities/parts/createSupplierCartPart';
import { supplierCartPartsItems } from './entities/parts/supplierCartPartsItems';
import { supplierCartParts } from './entities/parts/supplierCartParts';
import { createSupplierOrderOnlinePayment } from './entities/parts/createSupplierOrderOnlinePayment';
import { supplierOrder } from './entities/parts/supplierOrder';
import { supplierOrders } from './entities/parts/supplierOrders';
import { createSupplierOrder } from './entities/parts/createSupplierOrder';
import { liveSearch } from './entities/parts/liveSearch';
import { liveSearchResult } from './entities/parts/liveSearchResult';
import {
  BillCommodities
} from './entities/billing/commodity';
import {
  OnlinePayment
} from './entities/billing/payment';
import { workshopGoal } from './entities/dashboard/workshopGoal';
import {
  installedSuppliers,
  Suppliers,
} from './entities/parts/installedSuppliers';
import { setNewSupplierSettings } from './entities/parts/setNewSupplierSettings';
import { applySupplierSettings } from './entities/parts/applySupplierSettings';
import { suppliers } from './entities/parts/suppliers';
import { allSuppliers } from './entities/parts/allSuppliers';
import { supplierSettings } from './entities/parts/supplierSettings';

const API_RESOURCES = {
  /* ---------------------------------------------------------------- */
  clients: { fields: Clients },
  client: {
    fields: Client,
    mutations: ['updateClient', 'createClient'],
  },
  /* ---------------------------------------------------------------- */
  employees: { fields: Employees },
  employee: {
    fields: Employee,
    mutations: ['createEmployee', 'updateEmployee'],
  },
  employeeProfile: { resource: 'employee', fields: Employee },
  profile: { resource: 'profile', fields: Profile },
  softwares: { fields: Softwares },
  software: {
    fields: Software,
    mutations: ['createSoftware', 'updateSoftware'],
  },
  /* ---------------------------------------------------------------- */
  garages: { fields: Garages },
  garage: {
    fields: Garage,
    mutations: ['createGarage', 'updateGarage', 'changeGarageStatus'],
  },
  garagePosts: { fields: GaragePosts },
  garagePost: {
    fields: GaragePost,
    mutations: ['createGaragePost', 'updateGaragePost'],
  },
  garagePostTypes: { fields: GaragePostTypes },
  garagePostType: { fields: GaragePostType },
  garagePostReserves: { fields: GaragePostReserves },
  garagePostReserve: {
    fields: GaragePostReserve,
    mutations: ['createGaragePostReserve', 'updateGaragePostReserve'],
  },
  /* ---------------------------------------------------------------- */
  vehicles: { fields: Vehicles },
  globalVehicle: { fields: GlobalVehicle },
  vehicle: {
    fields: Vehicle,
    mutations: ['createVehicle', 'updateVehicle'],
  },
  vehicleManufacturers: { fields: VehicleManufacturer },
  vehicleManufacturer: { fields: VehicleManufacturer },
  vehicleModels: { fields: VehicleModel },
  vehicleModel: { fields: VehicleModel },
  vehicleModifications: { fields: VehicleModification },
  vehicleModification: { fields: VehicleModification },
  /* ---------------------------------------------------------------- */
  repairs: { fields: Repairs },
  // здесь мутация на создание подгрузку и изменение зн
  repair: {
    fields: Repair,
    mutations: ['createRepair', 'updateRepair', 'changeRepairStatus'],
  },
  repairWork: {
    fields: Work,
    mutations: ['createRepairWork', 'updateRepairWork'],
  },
  repairPart: {
    fields: Part,
    mutations: ['createRepairPart', 'updateRepairPart'],
  },
  /* ---------------------------------------------------------------- */
  vehicleWorkTimeCatalogs: { fields: VehicleModificationWorkCatalog },
  vehicleWorkTimeOwnCatalogs: {
    resource: 'vehicleWorkTimeCatalogs',
    fields: VehicleModificationWorkCatalog,
  },
  vehicleWorkGroups: { fields: VehicleWorkGroups },
  vehicleWorkTimes: { fields: VehicleWorkTimes },
  vehicleWorkTime: {
    fields: VehicleWorkTime,
    mutations: ['createVehicleWorkTime'],
  },
  vehicleWork: {
    fields: VehicleWork,
    mutations: ['createVehicleWork'],
  },
  file: {
    fields: File,
    mutations: [],
  },
  repairAddons: { fields: RepairAddons },
  repairAddon: {
    fields: RepairAddon,
    mutations: ['changeRepairAddonStatus', 'createRepairAddon', 'updateRepairAddon'],
  },
  addonWorks: { fields: AddonWorks },
  addonWork: {
    fields: AddonWork,
    mutations: ['createAddonWork', 'updateAddonWork'],
  },
  addonParts: { fields: AddonParts },
  addonPart: {
    fields: AddonPart,
    mutations: ['createAddonPart', 'updateAddonPart'],
  },
  vehicleModificationInfo: { fields: Recommendation },

  /* ---------------------------------------------------------------- */
  depots: { fields: Depots },
  depot: {
    fields: Depot,
    mutations: ['createDepot', 'updateDepot'],
  },
  importDepotItems: {
    fields: {
      id: true,
    },
    mutations: ['importDepotItemsTree'],
  },
  stockGroupsTree: { fields: StockGroup },
  stockGroupsTreeDict: {
    resource: 'stockGroupsTree',
    fields: StockGroup,
  },
  stocks: { fields: Stocks },
  stock: {
    fields: Stock,
    mutations: ['createStock', 'updateStock'],
  },
  measures: { fields: Measure },
  depotItems: { fields: DepotItemsWithChanges },
  depotItem: {
    fields: DepotItem,
    mutations: ['createDepotItem', 'updateDepotItem'],
  },
  depotItemChanges: { fields: DepotItemChanges },
  receiveDepotOperations: { fields: ReceiveDepotOperations },
  receiveDepotOperation: {
    fields: ReceiveDepotOperation,
    mutations: ['createReceiveDepotOperation'],
  },
  document: {
    fields: Document,
    mutations: ['createDocument', 'updateDocument'],
  },
  documentTypes: { fields: DocumentType },

  /* ---------------------------------------------------------------- */

  legalEntities: { fields: LegalEntities },
  legalEntity: {
    fields: LegalEntity,
    mutations: ['createLegalEntity', 'updateLegalEntity'],
  },
  individuals: { fields: Individuals },
  individual: {
    fields: Individual,
    mutations: ['createIndividual', 'updateIndividual'],
  },
  soleTraders: { fields: SoleTraders },
  soleTrader: {
    fields: SoleTrader,
    mutations: ['createSoleTrader', 'updateSoleTrader'],
  },
  getBankByBic: {
    fields: {
      bic: true,
      correspondentAccount: true,
      name: true,
      treasuryAccounts: true,
    },
  },
  bankDetails: { fields: BankDetails },
  bankDetail: {
    fields: BankDetail,
    mutations: ['createBankDetail', 'updateBankDetail'],
  },
  /* ---------------------------------------------------------------- */

  counterparties: { fields: Counterparties },
  counterparty: {
    fields: Counterparty,
    mutations: ['createCounterparty', 'updateCounterparty'],
  },
  findCounterparty: { fields: WorkshopCounterparty },
  /* ---------------------------------------------------------------- */
  workshopCounterparties: { fields: WorkshopCounterparties },
  workshopCounterparty: {
    fields: WorkshopCounterparty,
    mutations: ['createWorkshopCounterparty', 'updateWorkshopCounterparty'],
  },

  findWorkshopCounterparty: { fields: WorkshopCounterparty },
  /* ---------------------------------------------------------------- */

  garageBookings: { fields: Bookings },
  garageBooking: {
    fields: Booking,
    mutations: ['createGarageBooking', 'updateGarageBooking', 'changeGarageBookingStatus'],
  },
  /* ---------------------------------------------------------------- */
  signup: {
    fields: AccessToken,
    mutations: ['registerGarage'],
  },
  signIn: {
    fields: AccessToken,
    mutations: ['authenticateByCode'],
  },
  smsCode: {
    fields: VerificationCode,
    mutations: ['sendSmsVerificationCode', 'sendPhoneCallVerificationCode'],
  },
  smsCheck: {
    fields: { token: true },
    mutations: ['createSmsCheck'],
  },
  /* ---------------------------------------------------------------- */
  appeals: { fields: Appeals },
  offer: {
    fields: Offer,
    mutations: ['createOffer', 'updateOffer'],
  },
  offerWork: {
    fields: OfferWork,
    mutations: ['updateOfferWork'],
  },

  // ARTICLES
  /* ---------------------------------------------------------------- */
  articleCategories: { fields: ArticleCategories },
  articles: { fields: Articles },
  article: { fields: Article },
  /* ---------------------------------------------------------------- */

  timezones: { fields: { name: true, offset: true } },

  // DASHBOARDS
  /* ---------------------------------------------------------------- */
  getDashboardProfit: { fields: DashboardProfit },
  getDashboardRepair: { fields: DashboardRepair },
  getDashboardClients: { fields: DashboardClients },
  getDashboardAppealAndOffer: { fields: DashboardAppealAndOffer },
  getDashboardEmployee: { fields: DashboardEmployee },
  getDashboardGaragePost: { fields: DashboardGaragePost },
  getDashboardProfitRepairParts: { fields: DashboardProfitRepairParts },
  getBenchmarkWorkshop: { fields: BenchmarkWorkshop },
  getDashboardPaymentForWork: { fields: DashboardPaymentForWork },
  createWorkshopGoal: {
    fields: workshopGoal,
    mutations: ['createWorkshopGoal'],
  },
  updateWorkshopGoal: {
    fields: workshopGoal,
    mutations: ['updateWorkshopGoal'],
  },
  workshopGoals: { fields: workshopGoal },

  // CUSTOM REQUESTS
  /* ---------------------------------------------------------------- */
  globalSearch: { fields: GlobalSearch },

  // WORKSHOP REVIEWS
  /* ---------------------------------------------------------------- */
  workshopReviews: { fields: WorkshopReviews },
  workshopReview: {
    fields: WorkshopReview,
    mutations: ['updateWorkshopReview'],
  },
  // BILLING
  /* ---------------------------------------------------------------- */
  clientInvoices: { fields: ClientInvoices },
  clientInvoice: {
    fields: ClientInvoice,
    mutations: ['createBillClientInvoiceByWithdrawals'],
  },
  clientBillAccount: { fields: ClientBillAccount },
  billAccount: { fields: BillAccount },
  clientBillTransactions: { fields: ClientBillTransactions },
  billCommoditys: { fields: BillCommodities },
  billPayment: {
    fields: OnlinePayment,
    mutations: ['createBillPayment']
  },

  // SALARY
  /* ---------------------------------------------------------------- */
  payrollSchemeTemplates: { fields: PayrollSchemeTemplate },
  payrollSchemes: { fields: PayrollSchemes },
  payrollScheme: {
    fields: PayrollScheme,
    mutations: ['createPayrollScheme', 'updatePayrollScheme'],
  },
  paymentForWorks: {
    fields: PaymentForWorks
  },
  paymentForWork: {
    fields: { id: true },
    mutations: ['updatePaymentForWorks']
  },
  paymentForWorksGroups: { fields: PaymentForWorkGroups },

  // PERFORMERS
  /* ---------------------------------------------------------------- */
  repairWorkPerformers: {
    fields: Performer,
    mutations: ['applyRepairWorkPerformers'],
  },
  repairPartPerformer: {
    fields: { id: true },
    mutations: ['applyRepairPartPerformer'],
  },
  destroy: {
    fields: { id: true },
    mutations: ['destroyTill', 'destroyEmployee', 'destroySoftware', 'destroyGarage', 'destroyGaragePost', 'destroyGaragePostReserve', 'destroyVehicle', 'destroyRepair', 'destroyRepairWork', 'destroyRepairPart', 'destroyFileObject', 'destroyRepairAddon', 'destroyAddonWork', 'destroyAddonPart', 'destroyDepot', 'destroyStock', 'destroyDepotItem', 'destroyLegalEntity', 'destroyIndividual', 'destroySoleTrader', 'destroyBankDetail', 'destroyWorkshopCounterparty', 'destroyGarageBooking', 'destroyOffer', 'destroyPayrollScheme'],
  },
  // WORKSHOP INTEGRATIONS
  /* ---------------------------------------------------------------- */
  workshopIntegrations: { fields: WorkshopIntegrations },
  workshopIntegration: {
    fields: WorkshopIntegration,
    mutations: ['createQkkmWorkshopIntegration'],
  },
  tills: { fields: tills },
  till: {
    fields: till,
    mutations: ['createTill', 'updateTill'],
  },
  tillDocuments: { fields: TillDocuments },
  tillDocument: {
    fields: TillDocument,
    mutations: ['openTillSession', 'closeTillSession', 'printTillReport', 'printRepairPaymentTillDocument'],
  },
  repairPaymentOperations: { fields: RepairPaymentOperations },
  repairPaymentOperation: {
    fields: RepairPaymentOperation,
    mutations: ['acceptFinalRepairPayment'],
  },
  repairPayments: { fields: repairPayments },
  repairPayment: { fields: repairPayment },
  vehicleModelKinds: {
    fields: {
      id: true,
      name: true,
    },
  },

  // PARTS-EPLEX
  /* ---------------------------------------------------------------- */
  getDetails: { fields: getDetails },
  supplierParts: { fields: supplierParts },
  // createSupplierCartPart: {fields: createSupplierCartPart},
  supplierCartParts: {
    fields: supplierCartPartsItems,
  },
  supplierCartPart: {
    fields: supplierCartParts,
    mutations: ['createSupplierCartPart', 'updateSupplierCartPart', 'destroySupplierCartPart'],
  },
  createSupplierOrder: {
    fields: createSupplierOrder,
    mutations: ['createSupplierOrder'],
  },
  createSupplierOrderOnlinePayment: {
    fields: createSupplierOrderOnlinePayment,
    mutations: ['createSupplierOrderOnlinePayment'],
  },
  supplierOrder: {
    fields: supplierOrder,
  },
  supplierOrders: {
    fields: supplierOrders,
  },
  liveSearch: {
    fields: liveSearch,
  },
  liveSearchResult: {
    fields: liveSearchResult,
  },
  installedSuppliers: {
    fields: installedSuppliers,
  },
  setNewSupplierSettings: {
    fields: setNewSupplierSettings,
    mutations: ['setNewSupplierSettings']
  },
  applySupplierSettings: {
    fields: applySupplierSettings,
    mutations: ['applySupplierSettings', 'updateSupplier', 'destroySupplier']
  },
  suppliers: {
    fields: suppliers,
  },
  allSuppliers: {
    fields: allSuppliers
  },
  supplierSettings: {
    fields: supplierSettings
  }
};

export default API_RESOURCES;