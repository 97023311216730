import Pagination            from './../common/pagination';
import { Money }             from 'api/entities/common/money';

const PayrollSchemeTemplate = {
	group: true,
	name: true,
	type: true,
	inputKey: true,
	tags: true,
	properties: {
		name: true,
		key: true
	}
};

const PayrollScheme = {
	__typename: true,
	id: true,
	employeeId: true,
	group: true,
	payrollSchemeTemplate: PayrollSchemeTemplate,
	__on: [
		{
			__typeName: "Salary",
			fixedPayment: Money,
			settlementDay: true
		},
		{
			__typeName: "RepairPercent",
			percentPayment: true,
			withDiscount: true
		},
		{
			__typeName: "RepairForWorkPercent",
			percentPayment: true,
			withDiscount: true
		},
		{
			__typeName: "RepairForRepairPartPercent",
			percentPayment: true,
			withDiscount: true
		},
		{
			__typeName: "RepairFix",
			fixedPayment: Money,
		},
		{
			__typeName: "RepairForWorkFix",
			fixedPayment: Money,
		},
		{
			__typeName: "RepairForRepairPartFix",
			fixedPayment: Money,
		},
		{
			__typeName: "RepairWorkPercent",
			percentPayment: true,
			//withDiscount: true
		},
		{
			__typeName: "RepairPartExtraCharge",
			percentPayment: true,
			withDiscount: true
		},
		{
			__typeName: "RepairPartSale",
			percentPayment: true,
			withDiscount: true
		},
		{
			__typeName: "RepairWorkByHour",
			fixedPayment: Money,
		}

	]

}

const PayrollSchemes = {
	items: PayrollScheme,
	...Pagination
};

export {
	PayrollSchemeTemplate,
	PayrollSchemes,
	PayrollScheme
}