const Common = {
  minValueAllGarages: true,
  avgValueAllGarages: true,
  maxValueAllGarages: true,
  avgValueThisGarage: true
};

const BenchmarkWorkshop = {
  workshopId: true,
  dataBenchmarkWorkshop: {
    avgProfitEmployee: Common,
    avgProfitPost: Common,
    avgCostPerHour: Common,
    avgTotalPrice: Common,
    percentPartsRepair: Common,
    avgMarkupParts: Common
  }
};

export default BenchmarkWorkshop;