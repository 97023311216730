import { VehicleWorkTime } from '../../../vehicleWorkTime';

const VehicleModificationWorkGroup = {
  id: true,
  parentId: true,
  path: true,
  name: true,
  children: {
    id: true,
    parentId: true,
    path: true,
    name: true,
    workTimes: VehicleWorkTime
  },
  workTimes: VehicleWorkTime
};

export {
  VehicleModificationWorkGroup
}