const FILTER_SUPPLIER_PARTS = 'FILTER_SUPPLIER_PARTS';
const ADD_SELECT_PART = 'ADD_SELECT_PART';
const CHANGE_STATUS_TAB = 'CHANGE_STATUS_TAB';

export function filterSupplierParts (payload) {
  return {
    type: FILTER_SUPPLIER_PARTS,
    payload: payload
  }
}

export function addSelectedPart (payload) {
  return {
    type: ADD_SELECT_PART,
    payload: payload
  }
}

export function changeStatusTab (payload) {
  return {
    type: CHANGE_STATUS_TAB,
    payload: payload
  }
}

export const ACTION_HANDLERS = {
  [FILTER_SUPPLIER_PARTS]: (state, action) => {
    return {...state, filterParts: action.payload}
  },
  [ADD_SELECT_PART]: (state, action) => {
    return {...state, selectedPart: action.payload}
  },
  [CHANGE_STATUS_TAB]: (state, action) => {
    return {...state, statusTab: action.payload}
  },
};

const initialState = {
  filterParts: {
    filterByBrand: true,
    filterByAnalog: true,
  },
  selectedPart: {
    quantity: 1, availableCount: "-", article: "-", name: "-", brand: "-", cost: 0, plugin_id: "-", pos_idx: "-", stock: "-", supplierPartRequestId: "-"
  },
  statusTab: ""
};

const SparePartsReducer = (state = initialState, action) => {
  let handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

export default SparePartsReducer;