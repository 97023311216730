import { Employee } from './employee'

const Performer = {
  id: true,
  employeeId: true,
  employee: Employee,
  repairWorkId: true,
  percent: true
};

export {
  Performer
}