import { v4 as uuid }                      from 'uuid';
import { setCookie, getCookie }            from 'helpers/cookies';
import { ANONIMOUS_CLIENT_ID_COOKIE_NAME } from 'helpers/constants';
import isNull                              from 'lodash/isNull';

const cookie = require('cookie');

export function setAnonimousClientId (id) {
  try {
    setCookie(ANONIMOUS_CLIENT_ID_COOKIE_NAME, id);
  } catch {

  }
};

export function getAnonimousClientId (req) {
  let result = null;

  if (req) {
    if (req.headers.cookie) {
      result = cookie.parse(req.headers.cookie)[ANONIMOUS_CLIENT_ID_COOKIE_NAME] || null;
    }
  } else {
    result = getCookie(ANONIMOUS_CLIENT_ID_COOKIE_NAME);
  }

  if (isNull(result)) {
    result = uuid();
  }

  return result;
};