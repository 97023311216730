import User              from './user';
import { Garage }        from '../garage';
import { PayrollScheme } from '../payroll';

const Profile = {
  ...User,
  __on: [
    {
      __typeName: 'Employee',
      roles: true,
      garage: Garage,
      payrollSchemes: PayrollScheme,
    },
  ],
};

export {
  Profile,
};