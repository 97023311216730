import Pagination from './../common/pagination';

import { Address } from '../common/address';
import { BankDetail } from '.';

const LegalEntityCommon = {
  id: true,
  type: {
    __aliasFor: '__typename'
  },
  name: true,
  // bankDetails: BankDetail,
  counterpartyId: true,
  phone: true,
  email: true,
  inn: true,
  physicalAddress: Address,
  description: true
};

const WorkshopCounterparty = {
  __on: [
    /*{
      __typeName: 'WorkshopClient',
      fullName: {
        __aliasFor: "name",
        first:true,
        middle:true,
        last: true
      },
      clientId: true,
      workshopId: true,
    },*/
    {
      __typeName: 'WorkshopIndividual',
      ...LegalEntityCommon,
      // bankDetails: BankDetail,
      mainBankDetailId: true,
      registrationAddress: Address,
      personalData: true,
      bankDetails: {
        id: true,
        name: true,
        currentAccount: true,
        correspondentAccount: true,
        rcbic: true,
        counterpartyId: true,
        mainBankDetail: true,
        createdAt: true,
        updatedAt: true
      },
    },
    {
      __typeName: 'WorkshopSoleTrader',
      ...LegalEntityCommon,
      // bankDetails: BankDetail,
      psrnsp: true,
      // kpp: true,
      certificateNumber: true,
      certificateDate: true,
      legalAddress: Address,
      bankDetails: {
        id: true,
        name: true,
        currentAccount: true,
        correspondentAccount: true,
        rcbic: true,
        counterpartyId: true,
        mainBankDetail: true,
        createdAt: true,
        updatedAt: true
      },
    },
    {
      __typeName: 'WorkshopLegal',
      ...LegalEntityCommon,
      bankDetails: {
        id: true,
        name: true,
        currentAccount: true,
        correspondentAccount: true,
        rcbic: true,
        counterpartyId: true,
        mainBankDetail: true,
        createdAt: true,
        updatedAt: true
      },
      psrn: true,
      kpp: true,
      legalAddress: Address,
    },
  ],
};

const WorkshopCounterparties = { items: WorkshopCounterparty, ...Pagination };

export {
  LegalEntityCommon,
  WorkshopCounterparty,
  WorkshopCounterparties,
};