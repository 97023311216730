import Pagination            from './../common/pagination';

const VehicleWork = {
	id: true,
	name: true,
	action: true,
	workshopId: true,
	group: {
		id: true,
		name: true
	}
}

export {
	VehicleWork
}

export default VehicleWork;