import { Money } from '../common/money';

const DepotItemSnapshot = {
  depotId: true,
  depot: {
    id: true,
    name: true
  },
  vat: true,
  priceIn: Money,
  amount: {
    available: true,
    reserved: true,
    released: true
  },
};

export { DepotItemSnapshot };