const GaragePostType = {
	id: true,
	alias: true,
	category: true,
	createdAt: true,
	updatedAt: true
};

const GaragePostTypes = GaragePostType;

export {
	GaragePostType,
	GaragePostTypes
}