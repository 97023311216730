import Pagination from '../common/pagination';

const InstalledSuppliers = {
  id: true,
  pluginId: true,
  createdAt: true,
  updatedAt: true,
  properties: true,
  deletedAt: true,
  loaded: true,
  title: true,
  pluginName: true,
  icon: true,
}

const installedSuppliers = { items: InstalledSuppliers, ...Pagination };

export {
  installedSuppliers
}