import { Money }        from '../common/money';

export const OnlinePayment = {
  id: true,
  providerRef: {
    uid: true,
    name: true
  },
  orderId: true,
  paymentUrl: true,
  status: true,
  initialAmount: Money,
  currentAmount: Money,
  refundAmount: Money,
  createdAt: true,
  updatedAt: true
}