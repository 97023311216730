import Pagination            from './../common/pagination';
import { Money }             from '../common/money';
import { OfferWork }         from './offerWork'
import { Booking }           from '../booking'

const OfferListItem = {
	id: true,
	workshopId: true,
	appealId: true,
	status: true,
	totalPrice: Money,
	contentText: true,
	rejectedReason: true,
	works: [OfferWork],
	garageBookings: [Booking],
	createdAt: true,
	updatedAt: true,
	deletedAt: true
};

const Offer = {
	id: true,
	workshopId: true,
	appealId: true,
	status: true,
	totalPrice: Money,
	contentText: true,
	rejectedReason: true,
	works: [OfferWork],
	garageBookings: [Booking],
	createdAt: true,
	updatedAt: true,
	deletedAt: true
};

const Offers = { items: OfferListItem, ...Pagination };

export {
	Offer,
	Offers,
	OfferWork
}

export default Offer;