import Pagination  from './../common/pagination';
import { Money }   from './../common/money';
import { Address } from './../common/address';
import { File }    from './../common/files';
import GeoPoint    from './../common/geoPoint';
import {
	GaragePost,
	GaragePosts,
}                  from './post';
import {
	GaragePostType,
	GaragePostTypes,
}                  from './postType';
import {
	GaragePostReserve,
	GaragePostReserves,
}                  from './reserve';

import { GarageScheduleTemplate } from './template';
import { NotificationSettings }   from './notification';
import { BillAccount }            from '../billing/client/account';

const Garage = {
	id: true,
	type: true,
	name: true,
	description: true,
	address: Address,
	location: GeoPoint,
	phone: true,
	email: true,
	legalName: true,
	legalAddress: Address,
	bankName: true,
	checkingAccount: true,
	correspondentAccount: true,
	rcbic: true,
	inn: true,
	kpp: true,
	psrn: true,
	psrnsp: true,
	extraServices: true,
	logoPhotos: File,
	mainEntrancePhotos: File,
	clientZonePhotos: File,
	repairZonePhotos: File,
	otherPhotos: File,
	settings: {
		costPerHour: Money,
		defaultPartSurcharge: true,
		scheduleTemplate: GarageScheduleTemplate,
		vat: true,
		timezone: {
			name: true,
			offset: true,
		},
		notification: NotificationSettings,
		usedVehicleModelKind: true,

	},
	billAccount: BillAccount,
	status: true,
	balance: Money,
	createdAt: true,
	updatedAt: true,
};

const Garages = { items: Garage, ...Pagination };

export {
	Garage,
  Garages,
  GaragePost,
	GaragePosts,
	GaragePostType,
	GaragePostTypes,
  GaragePostReserve,
  GaragePostReserves
}