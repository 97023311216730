const Recommendation = {
	engine: {
    oils: { 
      capacity: {
        other: true,
        range: true,
        value: true,
        minimum: true,
        maximum: true
      }, 
      options: { 
        temperature: { 
          minimum: true,
          maximum: true
        },
        classifications: {
          value: true,
          qualifier: true
        },
        grades: { 
          value: true,
          qualifier: true
        }
      },
      tighteningTorques: {
        description: true,
        value: true,
        note: true
      }
    }
  },
  lubricants: {
    type: true,
    description: true,
    classifications: { 
      description: true,
      value: true
    },
    grades: { 
      description: true,
      value: true,
      qualifier: true
    },
    specifications: {
      description: true,
      value: true
    },
    capacities: {
      description: true,
      value: true,
      minimum: true,
      maximum: true,
      range: true
    },
    others: {
      info: true,
      description: true,
      value: true
    }
  }
};

export {
	Recommendation
}