import Pagination from './pagination'
import User from './user'
import { Address } from './address';

const { settings, ...UserWithoutSettings } = User

const Client = UserWithoutSettings

const Clients = { items: Client, ...Pagination };

export default Client;

export {
	Client,
	Clients
}
