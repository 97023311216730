import { ArticleListItem } from './index';
import Pagination          from './../common/pagination';

const ArticleCategory_BASE = {
  id: true,
  name: true,
  slug: true,
  privateArticle: true,
  createdAt: true,
  updatedAt: true
}

const ArticleCategory = {
  ...ArticleCategory_BASE,
  articles: ArticleListItem,
};

const ArticleCategories = {
  items: ArticleCategory,
  ...Pagination
};

export {
  ArticleCategory_BASE,
  ArticleCategories,
  ArticleCategory
};