import Pagination from './../common/pagination';
import {
  BankDetail,
  Individual,
  LegalEntity,
  SoleTrader,
}                 from './index';

const Counterparty = Object.assign({type: true}, LegalEntity, Individual, SoleTrader);

const Counterparties = {items: Counterparty, ...Pagination};

export {
  Counterparty,
  Counterparties
};