import { Money }     from './../common/money';
import { Discount }  from './../common/discount';
import { Performer } from '../common/performer';

const Work = {
  id: true,
  name: true,
  action: true,
  modificationWorkId: true,
  timeHrs: true,
  unitPrice: Money,
  unitCount: true,
  unitDiscount: Discount,
  discountPrice: Money,
  originalPrice: Money,
  hourlyRate: Money,
  totalPrice: Money,
  performers:Performer
}

export {
  Work
}