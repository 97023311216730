import Pagination             from './../common/pagination';
import { Address }            from './../common/address';
import { CommonCounterparty } from './common';

const SoleTrader = {
  ...CommonCounterparty,
  description: true,
  psrnsp: true,
  legalAddress: Address,
  certificateNumber: true,
  certificateDate: true
};

const SoleTraders = { items: SoleTrader, ...Pagination };

export {
	SoleTrader,
	SoleTraders
}