import Pagination from './../../common/pagination';

const till = {
  id: true,
  name: true,
  serialNumber: true,
  fiscalNumber: true,
  status: true,
  session: true,
  taxSystems: true,
  statusMessage: true,
  location: true,
  providerRel: {
    id: true,
    providerId: true,
  },
};

const tills = {items: till, ...Pagination};

export {
  till,
  tills,
};
