const Common = {
  thisMonth: true,
  thisAllTime: true
};

const DashboardAppealAndOffer = {
  workshopId: true,
  tableAppealAndOffer: {
    openAppeal: Common,
    acceptedOffers: Common,
    rejectedOffers: Common,
    avgTotalPrice: Common,
    maxTotalPrice: Common,
    countOffersToGive: Common,
    countOffersNotToGive: Common,
    countRelevantAppeal: Common
  }
};

export default DashboardAppealAndOffer;