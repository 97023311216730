const Common = {
  thisDay: true,
  yesterday: true,
  avg10day: true,
  avg30day: true,
  thisWeek: true,
  lastWeek: true,
  avg10week: true,
  thisMonth: true,
  lastMonth: true,
  avgYear: true,
  thisAllTime: true,
  allTime: true
};

const DashboardPaymentForWork = {
  ...Common
}

export default DashboardPaymentForWork;