import {
  AMPLITUDE_API_KEYS,
}                          from 'helpers/constants';
import { isProdAPI }       from 'helpers/helpers';
import ym                  from 'react-yandex-metrika';

const amplitudeKey = isProdAPI() ? AMPLITUDE_API_KEYS.prod : AMPLITUDE_API_KEYS.dev;
let amplitude;

const sendToAmplitude = (eventName, eventData) => {
  if (typeof window !== 'undefined') {
    amplitude = require('amplitude-js');
    amplitude.getInstance().init(amplitudeKey);
    amplitude.getInstance().logEvent(eventName, eventData);
  }
}

const sendToYM = (eventName, eventData) => {
  if (isProdAPI()) {
    ym('reachGoal', eventName, eventData);
  }
}

const sendToGA = (eventName) => {
  if (isProdAPI() && window.gtag) {
    window.gtag('event', eventName);
  }
}

const sendToAll = (eventName, eventData) => {
  sendToAmplitude(eventName, eventData);
  sendToYM(eventName, eventData);
  sendToGA(eventName);
}

const sendTo = (targets, eventName, eventData) => {
  const targetsMap = {
    amplitude: sendToAmplitude,
    ym: sendToYM,
    ga: sendToGA
  }

  if (Array.isArray(targets) && targets.length > 0) {
    targets.forEach((target) => {
      targetsMap[target](eventName, eventData);
    })
  }
}

const setUserId = (userId) => {
  if (typeof window !== 'undefined') {
    amplitude = require('amplitude-js');
    amplitude.getInstance().init(amplitudeKey);
    amplitude.setUserId(userId);
  }
}

const setUserProperties = (properties) => {
  if (typeof window !== 'undefined') {
    amplitude = require('amplitude-js');
    amplitude.getInstance().init(amplitudeKey);
    amplitude.setUserProperties(properties);
  }
}

export default function useProductEvents () {
  const productEvents = {
    send: sendToAll,
    sendTo: sendTo,
    setUser: setUserId,
    setUserProperties: setUserProperties
  }

  return productEvents;
};