import { Money }     from './../common/money';
import { Discount }  from './../common/discount';
import { DepotItem } from './../depot/items';

const Part = {
  id: true,
  repairId: true,
  depotItemId: true,
  name: true,
  description: true,
  article: true,
  oem: true,
  brand: true,
  unitPrice: Money,
  unitCount: true,
  unitDiscount: Discount,
  discountPrice: Money,
  originalPrice: Money,
  totalPrice: Money,
  depotItem: DepotItem,
  paymentItem: {
    id: true,
    name: true,
    status: true,
    unitCount: true,
    unitPrice: Money,
    totalAmount: Money,
    paidAmount: Money,
  },
  performerId: true,
  createdAt: true,
  updatedAt: true,
}

export {
  Part
}