import Pagination              from './../common/pagination';
import { Employee }            from './../common/employee';
import { Money }               from './../common/money';
import { Counterparty }        from './../counterparty';
import { WorkshopCounterparty }from './../counterparty';
import { Document }            from './../document';
import { DepotItem }           from './items';

const ReceiveDepotOperationItem = {
  stockId: true,
  depotId: true,
  depotItem: DepotItem,
  priceIn: Money,
  vat: true,
  amount: true
};

const ReceiveDepotOperation = {
  id: true,
  items: ReceiveDepotOperationItem,
  counterparty: {__aliasFor: "workshopCounterparty", ...WorkshopCounterparty},
  actor: Employee,
  document: Document,
  createdAt: true,
  updatedAt: true
};

const ReceiveDepotOperations = { items: ReceiveDepotOperation, ...Pagination };

export {
  ReceiveDepotOperation,
  ReceiveDepotOperationItem,
  ReceiveDepotOperations
}