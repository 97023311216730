import Pagination from './../common/pagination';

const Depot = {
  id: true,
  name: true,
  description: true,
	createdAt: true,
	updatedAt: true
};

const Depots = { items: Depot, ...Pagination };

export {
	Depot,
	Depots
}