import User       from './user';
import Pagination from './pagination';
import { Garage } from './../garage';

const Employee = {
  ...User,
  workshopId: true,
  garage: Garage,
  roles: true,
  inn: true,
  blocked: true,
  deletedAt: true
};

const Employees = { items: Employee, ...Pagination };

export {
  Employee,
  Employees
}