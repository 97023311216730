import Pagination     from './../common/pagination';
import { Money }      from './../common/money';
import { Vehicle }    from './../vehicle';

const GaragePostReserve = {
  id: true,
  startAt: true,
  endAt: true,
  garagePostId: true,
  garagePost: {
    id: true,
    name: true,
    garagePostType: {
      id: true,
      category: true
    }
  },
  repairId: true,
  repair: {
    id: true,
    description: true,
    status: true,
    performer: {
      id: true,
      name: { first: true, last: true, middle: true },
      workshopId: true,
      garage: { id: true, name: true },
      roles: true
    },
    totalPrice: Money,
    vehicle: Vehicle,
    createdAt: true
  },
	createdAt: true,
	updatedAt: true
};

const GaragePostReserves = { items: GaragePostReserve, ...Pagination };

export {
	GaragePostReserve,
	GaragePostReserves
}