import { Money }                  from '../common/money';
import Repair                     from '../repair';
import { RepairPaymentOperation } from '../repairPaymentOperation';
import Pagination                 from '../common/pagination';

const repairPayment = {
  id: true,
  repairId: true,
  currency: true,
  status: true,
  totalAmount: Money,
  paidAmount: Money,
  repair: Repair,
  paymentItems: {
    id: true,
    name: true,
    status: true,
    unitPrice: Money,
    unitCount: true,
    totalAmount: Money,
    paidAmount: Money,
  },
  paymentOperations: RepairPaymentOperation,
  createdAt: true,
};

const repairPayments = {items: repairPayment, ...Pagination};

export {
  repairPayment,
  repairPayments,
};