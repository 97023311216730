import Pagination              from './../../common/pagination';
import QkkmWorkshopIntegration from './Qkkm';

const WorkshopIntegration_BASE = {
  id: true,
  workshopId: true,
  workshop: {
    name: true
  },
  createdAt: true,
  updatedAt: true
};

const WorkshopIntegration = {
  ...WorkshopIntegration_BASE,
  integrationName: { __aliasFor: '__typename' },
  __on: [
    {
      __typeName: "QkkmWorkshopIntegration",
      ...QkkmWorkshopIntegration
    }
  ]
};

const WorkshopIntegrations = { items: WorkshopIntegration, ...Pagination };

export {
  WorkshopIntegration,
  WorkshopIntegrations
};