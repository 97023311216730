import Pagination from './../common/pagination';
import { Address } from './../common/address';
import { CommonCounterparty } from './common';

const Individual = {
  ...CommonCounterparty,
  registrationAddress: Address,
  personalData: true
};

const Individuals = { items: Individual, ...Pagination };

export {
  Individual,
  Individuals
}