import { DocumentType } from './type';
import { File }         from './../common/files';

const Document = {
	id: true,
	type: DocumentType,
	number: true,
	date: true,
	objectId: true,
	objectType: true,
	attachments: File,
	createdAt: true,
	updatedAt: true
};

export { Document };