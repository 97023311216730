const APPLY_USER         = 'APPLY_USER';
const RESET_USER         = 'RESET_USER';
const APPLY_USER_PROFILE = 'APPLY_USER_PROFILE';

const defaultHandler = (state, action) => {
  return {...state, ...action.payload}
}

export function applyUser (user) {
  return {
    type: APPLY_USER,
    payload: user
  }
}

export function applyUserProfile (profile = {}) {
  return {
    type: APPLY_USER_PROFILE,
    payload: { profile }
  }
}

export function resetUser () {
  return {
    type: RESET_USER,
    payload: {}
  }
}

export const ACTION_HANDLERS = {
  [APPLY_USER]: defaultHandler,
  [APPLY_USER_PROFILE]: defaultHandler,
  [RESET_USER]: (state, action) => {
    return {};
  }
};

const initialState = { profile: {} };

const AuthReducer = (state = initialState, action) => {
  let handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

export default AuthReducer;