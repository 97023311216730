import { Address } from './../common/address';
import { BankDetail } from './bankDetails';

const CommonCounterparty = {
  id: true,
  name: true,
  phone: true,
  email: true,
  type: true,
  inn: true,
  physicalAddress: Address,
  bankDetails: BankDetail,
  createdAt: true,
  updatedAt: true
}

export {
  CommonCounterparty
}