import { Money } from '../common/money';

const onlinePayment = {
  id: true,
  providerRef: {
    uid: true,
    name: true,
  },
  orderId: true,
  dependentObjectGid: true,
  description: true,
  paymentUrl: true,
  status: true,
  payType: true,
  initialAmount: Money,
  currentAmount: Money,
  refundAmount: Money,
  providerLogs: true,
  createdAt: true,
  updatedAt: true,
};

export { onlinePayment };