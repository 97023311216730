const Organization = {
  id: true,
  inn: true,
  name: true,
  email: true,
  phoneNumber: true,
  chief: true,
  contact: true,
  card: {
    legalAddress: true,
    postalAddress: true,
    physicalAddress: true,
    ogrn: true,
    transactionsAccount: true,
    corrAccount: true,
    bik: true,
    bankTitle: true
  }
};

const Till = {
  id: true,
  name: true,
  serialNumber: true,
  fiscalNumber: true,
  status: true,
  isOnline: true,
  isFiscalized: true,
  isPrintable: true,
  nodeId: true,
  node: {
    id: true,
    name: true,
    isOnline: true,
    versionString: true
  }
};

const QkkmWorkshopIntegration = {
  organization: Organization,
  openApiKey: { key: true },
  nodesRegKey: { key: true },
  tills: Till,
  createdAt: true,
  updatedAt: true
};

export default QkkmWorkshopIntegration;
export { Organization, Till };