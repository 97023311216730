const Common = {
  thisMonth: true,
  lastMonth: true,
  thisYear: true
};

const DashboardRepair = {
  workshopId: true,
  repairStatus: true,
  repairTable: {
    countRepair: Common,
    sumCostPerHour: Common,
    avgCostPerHour: Common,
    avgTotalPrice: Common,
    sumDiscountWorksPrice: Common,
    percentDiscountWorksPrice: Common,
    sumDiscountPartPrice: Common,
    percentDiscountPartPrice: Common
  }
};

export default DashboardRepair;